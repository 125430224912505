import React, { useState } from 'react'
import BackArrow from "../../../Assets/images/back_arraow.svg"
import { CustomTabs, CustomTabPanel } from '../Tabs';
import Subscription from '../Subscription';
import Sunscription_Raci from '../Sunscription_Raci';
function MoreInfo({ id, handleDrawerClose }) {
    const [initialTab, setInitialTab] = useState('Subscriptions_Explained');
    const [activeTab, setActiveTab] = useState(initialTab);

    const handleTabClick = (res) => {
        setActiveTab(res)
    }

    return (
        <div>
            <div className=''>
                <div className='row'>

                    <div className='col-lg-2'>
                        <div className='d-flex  justify-content-between align-items-center mb-3'>
                            <div onClick={handleDrawerClose}>
                                <img src={BackArrow} />
                            </div>

                            <div className='More_info'>
                                <p>More Information</p>

                            </div>

                        </div>
                    </div>
                    <div class="">
                        <div class="col-lg-3">
                            <div className="d-flex justify-content-between more_tab_wrap">
                                <div
                                    className={`d-flex align-items-center   py-2 Monthly px-4   ${activeTab === 'Subscriptions_Explained' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Subscriptions_Explained')}
                                >
                                    Subscriptions Explained
                                </div>
                                <div
                                    className={`d-flex align-items-center  Monthly px-4 py-2  ${activeTab === 'Subscriptions-RACI' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Subscriptions-RACI')}
                                >
                                    Subscriptions Explained - RACI
                                </div>
                            </div>




                        </div>


                    </div>

                    <div className='my-3'>
                        {
                            activeTab === 'Subscriptions_Explained' ? (<Subscription id={id} />) : (<Sunscription_Raci id={id} />)
                        }

                    </div>



                </div>

            </div>
        </div>
    )
}

export default MoreInfo