import React, { useContext } from "react";
import Ongoingservice from "../../Components/Common/Ongoingservice";
import Pastservice from "../../Components/Common/pastservice";
import Browserservice from "../../Components/Common/BrowserService";
import { useEffect, useState } from "react";
import { GETOWNSERVICEApi } from "../../Utils/services";
import AuthContext from "../../Utils/auth-context";
import Loader from "../../Components/Common/Loader";

const Myservices = () => {
  const [onGoing, setonGoing] = useState([]);
  const [onPast, setPast] = useState([]);
  const [loading, setloading] = useState(false);
  const data = localStorage?.getItem("user");

  const fetchData = async () => {
    setloading(true)
    try {
      const result = await GETOWNSERVICEApi(JSON.parse(data)?.companyId);
      if (result?.res?.status === true) {
        const category_1 = result?.res?.data?.filter(
          (res) => res?.projectType === 2 || res?.projectType === 1
        );
        const category_2 = result?.res?.data?.filter(
          (res) => res?.projectType === 3
        );
        setonGoing(category_1);
        setPast(category_2);
        setloading(false)
      }
    } catch {
      setloading(false)
    }
  };
  useEffect(() => {
    fetchData();
  }, [JSON.parse(data)?.companyId]);
 
  return (
    <div className="overview">
      <div>
        <h2 className="overviewhead">My Services</h2>
      </div>
      <Loader loading={loading} />
      {onGoing.length > 0 && <Ongoingservice data={onGoing} /> }
      {onPast.length > 0 &&  <Pastservice data={onPast} /> }
     
      {/* <Browserservice /> */}
    </div>
  );
};

export default Myservices;
