import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePages from "../../Pages/Home";
import Profilepage from "../../Pages/Myprofile";
import Servicepage from "../../Pages/Myservices";
import Servicewizard from "../../Pages/Servicewizard";
import ReviewPage from "../../Pages/Myservices/Review";
import PurchasePage from "../../Pages/Myservices/Purchase";
import Userupdate from "../../Pages/Home/Userupdate";
import Allservices from "../../Pages/Myservices/AllServices";
import Servicescopes from "../../Pages/Myservices/servicescope";
import Profileinfo from "../../Pages/Myprofile/Info";
import Selectservice from "../../Pages/Myservices/SelectedService";
import Payment from "../../Pages/Payment";
import Success from "../../Pages/Payment/Success";
import Error from "../../Pages/Payment/Error";
import ProfileWrap from "../../Pages/ProfileWrap";
import PlanDetails from "../../Pages/PlanDetails";
import Billing2 from "../../Pages/BillingForm";
import NotFound from "../../Pages/NotFound";
import Purchaseplan from "../../Pages/Purchaseplan";
// const HomePages = React.lazy(() => import("../../Pages/Home"))
// const Profilepage = React.lazy(() => import("../../Pages/Myprofile"))
// const Servicepage = React.lazy(() => import("../../Pages/Myservices"))

const MainWrapper = () => {
  return (
    <>
      <div className="main-wraper">
        <Routes>
          <Route exact path="/" element={<HomePages />} />
          <Route exact path="/userupdate/:id" element={<Userupdate />} />
          <Route exact path="/plandetails" element={<PlanDetails />} />
          <Route exact path="/Purchaseplan" element={<Purchaseplan />} />
          <Route exact path="/profile/profileinfo" element={<Profileinfo />} />
          <Route exact path="/services" element={<Servicepage />} />
          <Route exact path="/services/allservices" element={<Allservices />} />
          <Route exact path="/services/scopes" element={<Servicescopes />} />
          <Route exact path="/servicewizard" element={<Servicewizard />} />
          <Route exact path="/services/review/:id" element={<ReviewPage />} />
          <Route exact path="/services/purchase" element={<PurchasePage />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/error" element={<Error />} />
          <Route exact path="/profile" element={<ProfileWrap />} />
          <Route exact path="/billings" element={<Billing2 />} />
          <Route exact  path="*" element={<NotFound />} />
          <Route exact  path="/not-found" element={<NotFound />} />
          
          <Route
            exact
            path="/services/servicedetail/:id"
            element={<Selectservice />}
          />
        </Routes>
      </div>
    </>
  );
};
export default MainWrapper;
