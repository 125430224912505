import React from "react";
import { Field, ErrorMessage } from "formik";

function index(props) {
  const { name, type, placeholder, ...rest } = props;
  return (
    <div>
      <Field name={name}>
        {({ field, form }) => {
          // console.log(field,form,"test")

          
          
          return(
          <div className="row">
            <div>
              <input
                name={name}
                type={type}
                placeholder={placeholder}
                {...rest}
                {...field}
              />
              <div className="colorred">
              <ErrorMessage name={name} />
              </div>
          
            </div>
          </div>
        )}}
      </Field>
    </div>
  );
}

export default index;
