import React, { useState } from 'react';
import { Field } from 'formik';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


function PhoneNumber(props) {
    const { name, type, placeholder, value, ...rest } = props;
    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div>
                            <PhoneInput
                             defaultCountry="IN"
                                {...field}
                                {...rest}
                                // country={'in'}
                                value={field.value} // Use field.value to populate the input
                                onChange={(value) => form.setFieldValue(name, value)} // Use setFieldValue to update Formik's state
                            />

                        </div>
                    </div>
                )}
            </Field>
        </div>
    );
}

export default PhoneNumber;
