import React, { useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "react-google-login";
import logo from "../../../Assets/images/logo_new.png";
import loginbannerimg from "../../../Assets/images/Login_Banner.png";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../Utils/auth-context";
import { toast } from "react-toastify";
import logo1 from "../../../Assets/images/window.svg";
import { loginService } from "../../../Utils/services";
import Google from "../Google/index";
import Microsoft from "../Microsoft/index";
import style from "../Log/log.module.css";
import { useMsal } from "@azure/msal-react";
import Loader from "../Loader";
import { useParams } from 'react-router-dom';
const Login = () => {
  const [loading, setloading] = useState(false);
  const { instance, inProgress } = useMsal();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id, plan } = useParams();

  const clientId = "260794713610-p9pd3lvdutj6q482af1hmcq7aa51urbj.apps.googleusercontent.com";
  const onLoginSuccess = async (response) => {
    if (response) {
      
      const data = {
        idToken: response?.tokenId, // Use correct property name
        isEmployee: "false",
        loginProvider: 1,
        name: response?.profileObj?.name,
        imageUrl: response?.profileObj?.imageUrl,
        email: response?.profileObj?.email,
        planId: plan,
        catId: id
      };

      try {
        setloading(true);
        const result = await loginService(data);

        if (result?.res.status === true) {

          authCtx.login(result?.res?.data);
          localStorage.setItem("user", JSON.stringify(result?.res?.data));
          localStorage.setItem("access_token", result?.res?.data?.accessToken);
          localStorage.setItem("catId", result?.res?.data?.catId);
          localStorage.setItem("planId", result?.res?.data?.planId);

          if (!result?.res?.data?.companyId) {
            setloading(false);
            navigate("/profile/");
          } else if (id && plan && result?.res?.data?.companyId) {
            setloading(false);
            navigate("/billing");
          }
          else {
            setloading(false);
            navigate("/");
          }

        }
      } catch (error) {
        setloading(false);
        toast.error(error?.message);

        // Handle error from loginService
      }
    }
  };
  const onLoginFailure = (error) => {
    console.error("Google login failure:", error);
    // Handle login failure here
  };
useEffect(()=>
{
  localStorage.setItem('plans_cat',id);
})
  const { signIn } = useGoogleLogin({
    clientId,
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailure,
    cookiePolicy: "single_host_origin",
  });
  return (
    <div className="">
      <Loader loading={loading} />
      <div className={style.loginlogo}>
        <div className={style.loginlogoimg}>
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="columns-1 flex fnlogin">
        <div className="w-3/5 flex justify-center items-center bannlogin">
          <div className={style.loginbannerimg}>
            <img src={loginbannerimg} alt="" />
          </div>
        </div>
        <div className="w-2/5 flex justify-start items-center">
          <div>
            <div className="">
              <h1 className={style.loginhead}>Welcome</h1>
              <p className={style.logindesc}>Log In with to continue</p>
            </div>
            <div className={style.sociallogin}>
              <div className={style.signgoogle} onClick={signIn}>
                <Google />

                {/* <div>
                <span className={style.logintext}>Sign in with Google</span></div> */}
              </div>
              <div className={style.signmicro}>
                <div
                  onClick={() => {
                    instance
                      .loginPopup({
                        scopes: ["email"],
                      })
                      .then((result) => {

                      })
                      .then((result) => {

                      })
                      .catch((err) => {

                      });
                  }}
                >
                  <Microsoft />
                </div>

                {/* <div className={style.loginbox}><span className={style.logintext}>
                  Sign in with Microsoft
                </span></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
