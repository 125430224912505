import React from "react";
import styles from "../servicewizards/servicewizards.module.css";
import servewizardimg from "../../../Assets/images/Rectangle .png";
import { Formik, Form } from "formik";
import FormControl from "../controls/formcontrol";

const data = [
  {
    id: 1,
    title: "Are you looking for -",
    text1: "comprehensive cybersecurity solution",
    text2: "specific services",
  },
  {
    id: 2,
    title: "What will you prefer?",
    text1: "managed security services",
    text2: "an in-house team",
  },
  {
    id: 3,
    title: "Do you require 24/7 security monitoring and threat intelligence?",
    text1: "Yes",
    text2: "No",
  },
];

const index = () => {
  return (
    <div>
      <>
        <div className="overviewback">
          <div className={styles.servwizard}>
            <div>
              <div>
                <h2 className={styles.servwizardhead}>Service Wizard</h2>
                <span className={styles.servwizardesc}>
                  Please provide the following details and we will find a
                  service.
                </span>
              </div>
              {data.map((findservice) => (
                <Formik>
                  <Form>
                    <div key={findservice.id} className={styles.wizform}>
                      <div>
                        <h3 className={styles.wizardsubhead}>
                          {findservice.title}
                        </h3>
                      </div>
                      <div className={styles.statform}>
                        <div className={styles.formchecks}>
                          <div>
                            <FormControl control="Checkbox" className={styles.wizardcheck} />
                          </div>
                          <div><span>{findservice.text1}</span></div>
                        </div>
                        <div className={styles.formcheck}>
                          <div>
                            <FormControl control="Checkbox" className={styles.wizardcheck} />
                          </div>
                          <div><span>{findservice.text2}</span></div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              ))}
            </div>
            <div>
              <div className={styles.serveimgwizard}>
                <img src={servewizardimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default index;
