import React from 'react'
import Scope from "../../../Components/Common/scope";

const index = () => {
  return (
    <div>
      <Scope />
      
    </div>
  )
}

export default index
