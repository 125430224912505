import { Formik, Form } from 'formik';
import React, { useContext, useState,useEffect } from "react";
import * as Yup from 'yup';
import FormControl from '../../Components/Common/controls/formcontrol';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATESUBSCRPTIONAPI, GETPROFILEAPI, GetCustomerApi, UPDATEPROFILEAPI } from '../../Utils/services';
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import AuthContext from '../../Utils/auth-context';
import Loader from '../../Components/Common/Loader';

function AddBillingDetail() {


    const authCtx = useContext(AuthContext);
    const [allData, setAllData] = useState({});
    const [loading, setloading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const yourData = location.state?.yourData;
     console.log(yourData,"yourData")


     const fetchData = async () => {
        const isJSONString = (str) => {
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        };
    
        if (isJSONString(authCtx?.user)) {
          const data = JSON?.parse(authCtx?.user);
    
          try {
            setloading(true);
            const result = await GetCustomerApi(data?.companyId);
            if (result && result?.res.status === true) {
              const mergedData = { ...data, ...result?.res?.data && result?.res?.data?.billingDetails};
              setAllData(mergedData);
              console.log(mergedData,"mergedData")
              setloading(false);
            } else {
              setloading(false);
            }
          } catch (error) {
            setloading(false);
            // Fallback to test data
          }
        } else {
          const data = authCtx?.user;
    
          try {
            setloading(true);
            const result = await GetCustomerApi(data?.companyId);
            if (result && result?.res.status === true) {
              const mergedData = { ...data, ...result?.res?.data && result?.res?.data?.billingDetails };
              setAllData(mergedData);
              console.log(mergedData,"mergedData")
              setloading(false);
            } else {
              setloading(false);
            }
          } catch (error) {
            setloading(false);
            // Fallback to test data
          }
        }
      };
    
      useEffect(() => {
        fetchData();
      }, [authCtx?.user]);


console.log(allData,"allData")
    const handleSubmit = async (values) => {  debugger
        // const data = {
        //     ...values, ...yourData
        // };
        const data = new FormData();
        // data.append("userProfileImage", image?.raw)
        data.append("billingEmail", values.billingDetails?.billingemail)
        data.append("userEmail", values.billingDetails?.billingemail)
        data.append("phoneNumber", values.billingDetails?.phoneNumber)
        data.append("fullName", values.billingDetails?.fullName)
        data.append("address1", values.billingDetails?.address1)
        data.append("address2", values.billingDetails?.address2)
        data.append("zipCode", values.billingDetails?.zipCode)
        data.append("state", values.billingDetails?.state)
        data.append("country", values.billingDetails?.country)
        data.append("city", values.billingDetails?.city)
        data.append("gstNumber", values.billingDetails?.billinggst)
        console.log(data,"apidata")
        console.log(values,"values")
        try {
            const result = await UPDATEPROFILEAPI(data);
              console.log(result.status,"result.res.status")
            if (result.status === true) {
                toast.success("Redirecting to the payment screen. If your purchase is successful, it will be reflected in the dashboard in a few minutes.");
               // window.open(result.res.data);
                navigate('https://services.fncyber.com/billingform');
            }
        } catch (err) {
            // Handle error
        }
    };

    const validationSchema = Yup.object().shape({
        billingDetails: Yup.object().shape({
            fullName: Yup.string().required('Full Name is required'),
            address1: Yup.string().required('Address Line 1 is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zipCode: Yup.string().required('Zip Code is required'),
            country: Yup.string().required('Country is required'),
            billinggst: Yup.string().required('Gst No. is required'),
            billingemail: Yup.string().email('Invalid email address').required('Email Address is required'),
            phoneNumber: Yup.string()
                .required('Phone Number is required')
                .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Phone number must be a valid international phone number')
        }),
    });
 console.log(allData[0],"data")
    return (
        <>
        <Loader loading={loading} />
        <div className=" d-flex justify-content-left align-items-center mt-5">
            {allData && allData.name && (
                <Formik
                    initialValues={{
                        billingDetails: {
                            fullName: allData[0]?.fullName && allData[0]?.fullName   || "" ,
                            address1: allData[0]?.address1 && allData[0]?.address1 || "" ,
                            address2: allData[0]?.address2 && allData[0]?.address2 || "",
                            city: allData[0]?.city && allData[0]?.city || "",
                            state: allData[0]?.state && allData[0]?.state || "",
                            zipCode: allData[0]?.zipCode  && allData[0]?.zipCode|| "",
                            country: allData[0]?.country && allData[0]?.country || "",
                            billinggst: allData[0]?.gstNumber && allData[0]?.gstNumber || "",
                            billingemail: allData[0]?.billingEmail && allData[0]?.billingEmail || "",
                            phoneNumber: allData[0]?.phoneNumber  && allData[0]?.phoneNumber || "",
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form className="col-lg-10">
                            <div className="plan_wrapper form_profile">
                                <div className="plan_head">
                                    <h1>Billing Details</h1>
                                </div>
                                <div className="row gy-3">
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            name="billingDetails.fullName"
                                            placeholder="Full Name"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="Address Line 1"
                                            name="billingDetails.address1"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="Address Line 2"
                                            name="billingDetails.address2"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="City"
                                            name="billingDetails.city"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="State"
                                            name="billingDetails.state"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="Zip"
                                            name="billingDetails.zipCode"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="Country"
                                            name="billingDetails.country"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControl
                                            control="Input"
                                            placeholder="Email Address"
                                            name="billingDetails.billingemail"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <PhoneInput
                                            defaultCountry="IN"
                                            countryCodeEditable={true}
                                            value={formik.values.billingDetails.phoneNumber}
                                            onChange={(value) => formik.setFieldValue('billingDetails.phoneNumber', value)}
                                        />
                                        {formik.errors.billingDetails?.phoneNumber && formik.touched.billingDetails?.phoneNumber ? (
                                            <div className="error">{formik.errors.billingDetails.phoneNumber}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-6 billinggst">
                                        <FormControl
                                            control="Input"
                                            placeholder="Gst No."
                                            name="billingDetails.billinggst"
                                            className=" billinggstin"
                                        />
                                             <div class="tooltip-containers">
  <button class="info-buttons">i</button>
  <div class="tooltip-content">This is the tooltip text.</div>
  </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="px-4 py-2 Monthly active">
                                            <button type="submit">Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    </>
);
    
}

export default AddBillingDetail;
