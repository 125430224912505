import React from "react";
import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useField } from "formik"; // Import useField from Formik
import styles from "../ratings/ratings.module.css";

function Star(props) {
  const { readonly, name } = props; // Rename 'count' to 'name' to match Formik field name

  // Use Formik's useField hook to access field and meta data
  const [field, meta, helpers] = useField(name);

  const handle = (value) => {
    // Use Formik's setValue to update the field value
    helpers.setValue(value);
  };

  return (
    <div className="cursor-pointer">
      <Rating
        initialRating={field.value} // Use field.value from Formik
        stop={5}
        readonly={readonly}
        emptySymbol={<FaRegStar className={styles.icon} />}
        fullSymbol={<FaStar className={styles.icon} />}
        onClick={handle}
      />
    </div>
  );
}

export default Star;
