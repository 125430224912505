import React, { useContext ,useRef} from "react";
import DownloadBtn from "../button";
import styles from "./downloadlinks.module.css";
import DownloadFile from "../Modals/DownloadFile";
import { ModalContext } from "../../../Utils/Context";

const Downloadlinks = (props) => {
  const { data } = props;
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const Download = <DownloadFile closeModal={closeModal} data={data} />;
  const handleDownloadFile = () => {
    handleModalData(Download, "md");
  };
  return (
    <>
      <div className={styles.linksfiles} ref={props.raciComments}>
        <div className={styles.linkhead}>
          <div>Links</div>
          <div onClick={handleDownloadFile}>
            <DownloadBtn buttonText="Download Files" />
          </div>
        </div>

        <div className="row">
          <div className={styles.linksheadtitle}>
            <div className="col-lg-3">
              <div>
                <h4>Folder</h4>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <h4>Title</h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <h4>URL</h4>
              </div>
            </div>
          </div>

          {data &&
            data?.linkList?.map((filelinks) => (
              <div key={filelinks.id} className={styles.linkstitle}>
                <div className="col-lg-3">
                  <div>
                    <h5 className={styles.linksdetail}>
                      {filelinks.isProject === true
                        ? "ProjectLink"
                        : "ServiceLink"}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div>
                    <h5 className={styles.linksdetail}>
                      {filelinks?.linkName}
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <h5 className={styles.linksdetail}>{filelinks?.linkUrl}</h5>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Downloadlinks;
