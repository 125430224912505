import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../Utils/auth-context";
import Sidebar from "../Layout/sidebarlist";
import styles from "../Layout/sidenav.module.css";
import signout from "../../Assets/images/Sign_out.svg";
import { Link, useNavigate } from "react-router-dom";

const Sidenav = () => {
  const [activeLink, setActiveLink] = useState({ 0: true });
  const [listShow, setListShow] = useState({ 0: true });
  const [buttonid, setButtonId] = useState(0); // Initialize with the default item

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const handlelog = () => {
    localStorage.clear();
    navigate("/login");
  };

  const listHandler = (id, sublist, listRef) => {
    setActiveLink({ [id]: true });

    if (sublist !== undefined && !listShow[listRef]) {
      setListShow({ [listRef]: true });
    } else {
      setListShow({ [listRef]: false });
    }

    // Update the buttonid to the clicked item's id
    setButtonId(id);
  };

  return (
    <>
    <div className="sideham">
      <div className={styles.sidenavhead}>
        <ul className="side-menu">
          {Sidebar.map((list, id) => {
            let sublist = list.sublist;
            let listRef = list.ref;
            let labelText = list.label;
            let path = list.path;
            let src = list.images;
            let src1 = list.clrimages;

            return (
              <React.Fragment>
                <li
                  key={list.id}
                  id={id}
                  onClick={() => {
                    if (sublist === undefined) {
                      listHandler(id, sublist, listRef);
                    }
                  }}
                >
                  <NavLink
                    to={`${path}`}
                    onClick={() => {
                      listHandler(id, sublist, listRef);
                    }}
                    className={`${activeLink[id] ? styles.active : ""}`}
                  >
                    <div className={styles.sidemain}>
                      <div className="flex">
                        <div className={styles.sidenavimg}>
                          <img src={id === buttonid ? src1 : src} alt="" />
                        </div>
                        <div className={styles.sidenavdesc}>
                          <span className={styles.sidenavtext}>
                            {labelText}
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
      <div className={styles.signout}>
        <div className="flex" onClick={handlelog}>
          <div className={styles.sidenavimg}>
            <img src={signout} alt="" />
          </div>
          <div className={styles.sidenavdesc}>
            <span className={styles.sinotext}>Sign Out</span>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Sidenav;
