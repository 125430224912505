import React from "react";
import { Link } from "react-router-dom";
import styles from "../pastservice/pastservice.module.css";
import download from "../../../Assets/images/download.png";
import Mainbtn from "../../../Components/Common/button";

const index = ({ data }) => {


  return (
    <div className={styles.mainpastserve}>
      <div className={styles.pastheadservicetile}>
        <h3>Past Services ({data?.length})</h3>
      </div>
      <div class="grid  lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
        {data && data?.map((service) => (
          <div className={styles.pastservicetile}>
            <div className={styles.servicetilepast}>
              <div>
                <h4 className={styles.pastservicename}>{service.name}</h4>
              </div>
              <div className="">
                <img src={download} alt="" />
              </div>
            </div>
            <div className={styles.pastanalystname}>
              <span>{service.serviceName}</span>
            </div>
            <div className="flex">
              <div className="mr-2">
                <span>Finished on :</span>
              </div>
              <div className={styles.pastservdate}>
                <span>{service?.finishedOn}</span>
              </div>
            </div>


            <div className={styles.pastbtn}>
              <Link to={`/services/review/${service?.purchasetaskId}`}>
                <Mainbtn buttonText="Write Review" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default index;
