import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from "../BrowserService/browserservice.module.css";
// import styles from "../ServiceList/allservice.module.css";
import styless from "../ServiceList/allservice.module.css";
import logo from "../../../Assets/images/tacticalservices.png";
import { GETADDONSAPI, GetPatServiceApi } from "../../../Utils/services";
import { useState } from "react";
const data = [
  {
    
      "id": "e67dffc2-3d31-4709-9767-d2fc9dfab497",
      "title": "Essential Service",
      "img": 30,
      "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
      "slug": "Essential Service",
      "value": 4
    },
    {
      "id": "1656200d-d0d9-4f05-95ce-6d28dad73010",
      "title": "Operational Services",
      "img": 30,
      "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
      "slug": "Operational Service",
      "value": 1
    },
    {
      "id": "d60ecd51-2244-42ae-8ede-be2f1b07f09e",
      "title": "Tactical Services",
      "img": 28,
      "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
      "slug": "Tactical Service",
      "value": 2
    
  //   "id": 4,
  //   "title": "Essential Service",
  //   "img": 30,
  //   "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
  //   "slug": "Essential Service"
  // },
  // {
  //   "id": 1,
  //   "title": "Operational Services",
  //   "img": 30,
  //   "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
  //   "slug": "Operational Service"
  // },
  // {
  //   "id": 2,
  //   "title": "Tactical Services",
  //   "img": 28,
  //   "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
  //   "slug": "Tactical Service",
  // },
  // {
  //   "id": 3,
  //   "title": "Strategic Services",
  //   "img": 35,
  //   "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. A, nobis.",
  //   "slug": "Strategic Service",
  }
]

const BrowserService = () => {
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleClick = (data) => {
    console.log(data,"/services/allservices")
    navigate(`/services/servicedetail/${data?.id}`,{state: data });
    // navigate('/services/allservices', { state: data });
  }

  const fetchData = async () => {
    setLoading(true);
    try {
        const result = await GETADDONSAPI();
        if (result && result?.res.status === true) {
            setLoading(false);
            console.log(result.res.data,"result.res.data")
            setServiceList(result.res.data);
        }
        else {

        }
    } catch (error) {
        setLoading(false);
        // Fallback to test data
    }
};

useEffect(() => {
    fetchData();
}, []);
console.log(serviceList,"serviceList")
  return (
    <div className={styles.mainbrowserservice}>
      <div><h3 className={`pt-3 ${styles.mainheadbrowsetile}`}>Subscription services</h3></div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-9 pt-2">
        {data.map((service, i) => (

          <div key={service.id} className={styles.browserservicetile} onClick={() => handleClick(service)}>
            <div className={styles.browsersimg}>
              <img src={logo} alt="" />
            </div>
            <div className={styles.browserservicedesc}>
              <h4 className={styles.headbrowsetile}>
                {service.title}
              </h4>
              <p className={styles.browsetile}>{service.desc}</p>
            </div>
          </div>

        ))}
      </div>

      
                
                    
                

                     
                            <div className={styless.allserve} >
                              <div><h3 className={`pt-7 ${styles.mainheadbrowsetile}`}>Add On services</h3></div>
                                <div className={styless.allmainserves}>
                                    <div className={styless.cardhead}>
                                        <h2 className={styless.allservehead}>{}</h2>
                                    </div>
                                    <div className={styless.servecards}>
                                        <div className={styless.servelist}>
                                            <div className="row m-0">
                                                {serviceList
                                                    
                                                    ?.map((allservice, index) => (
                                                        <div className="col-lg-6" key={allservice.serviceId}>
                                                            <div className={styless.mainservice}>
                                                                <div className={styless.squaresmall}>
                                                                    <div>{index + 1}</div>
                                                                </div>
                                                                <Link to={`/services/servicedetail/${allservice?.serviceId}`}>
                                                                    <div className={styless.allservetext}>
                                                                        <span>{allservice.serviceName}</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                  
            
    </div>
  );
};

export default BrowserService;
