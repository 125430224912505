import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import FormControl from "../../controls/formcontrol";
import Submitbtn from "../../button";
import styles from "./profileinfo.module.css";
import Loader from "../../Loader";
import { CustomerCreate, GetCustomerApi, UPDATEPROFILEAPI, GETPROFILEAPI } from "../../../../Utils/services";
import * as Yup from "yup";
import AuthContext from "../../../../Utils/auth-context";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { FaCircle, FaStore } from 'react-icons/fa';
import addprofile from "../../../../Assets/images/addprofile.png";

const Profileinfo = () => {
  const authCtx = useContext(AuthContext);
  const [userData, SetUserData] = useState();
  const [loading, setloading] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [CompanyData, SetCompanyData] = useState({});

  const fetchData = async () => {
    setloading(true);
    const isJSONString = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };
    if (isJSONString(authCtx?.user)) {
      const data = JSON?.parse(authCtx?.user);
      const parsedUserData = JSON.parse(authCtx.user);

      try {
        const result = await GETPROFILEAPI();
        if (result && result?.res.status === true) {
          if (isJSONString(authCtx.user)) {
            // Merge allData and parsedUserData
            const mergedData = { ...result?.res?.data, ...parsedUserData };
            SetUserData(mergedData);
            setImage({ ...image, preview: result?.res.data?.imageUrl });
            setloading(false);
          } else {
            SetUserData(parsedUserData);
            setloading(false);
          }
        } else {
          SetUserData(parsedUserData);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        SetUserData(parsedUserData);
      }
    } else {
      const data = authCtx?.user;
      const parsedUserData = authCtx.user;
      try {
        const result = await GETPROFILEAPI();
        if (result && result?.res.status === true) {
          // Merge allData and parsedUserData
          const mergedData = { ...result?.res?.data, ...parsedUserData };
          SetUserData(mergedData);
          setImage({ ...image, preview: result?.res.data?.imageUrl });
          setloading(false);
        } else {
          SetUserData(parsedUserData);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        SetUserData(parsedUserData);
        // Fallback to test data
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handle = async (values) => {
  
    const data = new FormData();
    data.append("userProfileImage", image?.raw)
    data.append("userEmail", values.formData?.email)
    data.append("userNumber", values.formData?.phone)
    data.append("userName", values.formData?.name)

    try {
      setloading(true);
      const result = await UPDATEPROFILEAPI(data);
      if (result?.status == true) {
        setloading(false);
        toast.success(result?.message)
      }
      else {
        setloading(false);
        toast.success(result?.message);
      }
    } catch (error) {
      toast.error(error || error.message);
      setloading(false);
    }

  };
  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const schema = Yup.object().shape({

  });

  return (
    <>
      <Loader loading={loading} />
      <div>
        {/* <h2 className={styles.profhead}>Profile</h2> */}
        {/* <span className={styles.prodetail}>
          Please provide the following details, it will help us serve you better
        </span> */}
      </div>
      {userData && (
        <Formik
          initialValues={{
            formData: {
              name: userData?.ownerName ? userData?.ownerName : userData?.name,
              email: userData?.email || "",
              primaryRole: userData?.primaryRole || "",
              phone: userData?.ownerMobileNo,
              userProfileImage: userData?.userProfileImage

            },
          }}
          onSubmit={handle}
          validationSchema={schema}
        >
          {(formik) => (
            <Form className={styles.form_profile}>

              <div className="row">
                <div className="col-lg-12">
                  <div className={styles.promain}>
                    <div className={styles.proabt}>
                      <div>
                        <h3 className={styles.profilesubhead}>About You</h3>
                      </div>
                      <div className={styles.proimg}>
                        <label htmlFor="upload-button" style={{cursor: "pointer"}}>
                          {image.preview ? (
                            <img src={image.preview} alt="dummy" width="100" height="100" style={{borderRadius:"25%"}} />
                          ) : (
                            <>
                              <div className={styles.profileeditimg}>
                                <div className={styles.profileedittext}>
                                  <div className={styles.profileeditimgage}>
                                    <img src={addprofile} alt="" />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />


                      </div>
                      <div className={styles.proedit}>
                        <FormControl
                          control="Input"
                          placeholder="Name"
                          name="formData.name"

                        />
                      </div>
                      <div className={styles.proedit}>
                        <FormControl
                          control="Input"
                          placeholder="Email Id"
                          name="formData.email"
                          disabled
                        />
                      </div>
                      <div className={styles.proedate}>
                        <div className="row">
                          <div className="col-lg-5">
                            <FormControl
                              control="Input"
                              placeholder="Role"
                              name="formData.primaryRole"
                              disabled
                            />
                          </div>
                          <div className="col-lg-7">
                            <FormControl
                              control="Phone"
                              placeholder="Phone"
                              name="formData.phone"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className={styles.proorganisat}>
                        <div>
                          <h3 className={styles.profilesubhead}>
                            About Organisation
                          </h3>
                        </div>

                        <div className={styles.proedit}>
                          <FormControl
                            control="Input"
                            placeholder="Name of Organisation"
                            name="formData.organisationName"

                          // value={Formik.values.formData?.organisationName}
                          />
                        </div>
                        <div className={styles.profileorgani}>
                          <div className="row">
                            <div className="col-lg-6">
                              <FormControl
                                control="Input"
                                placeholder="Industry"
                                name="formData.industry"
                              />
                            </div>
                            <div className="col-lg-6">
                              <FormControl
                                type="number"
                                control="Input"
                                placeholder="Company size"
                                name="formData.companySize"
                              />
                            </div>
                          </div>
                        </div>
                        <Loader loading={loading} />
                        <div className={styles.proedit}>
                          <FormControl
                            control="Input"
                            placeholder="Location"
                            type="text"
                            name="formData.location"
                          />
                        </div>
                        <div className={styles.proedit}>
                          <span className={styles.prodetail}>
                            What type of sensitive data does your organisation
                            handle?
                          </span>
                          <FormControl
                            control="Input"
                            type="text"
                            placeholder="(e.g.,customer information,financial data, intellectual property)"
                            name="formData.typeofSensitiveData"
                          />
                        </div>
                      </div> */}
                  </div>
                </div>

                {/* <div className="col-lg-7">
                    <div className={styles.profilequest}>
                      <div>
                        <h3 className={styles.profilesubhead}>
                          About Security
                        </h3>
                      </div>

                      <div className={styles.securitydesc}>
                        <span className={styles.prodetail}>
                          What are your primary cybersecurity concerns or
                          challenges?
                        </span>
                        <FormControl
                          control="Textarea"
                          placeholder=""
                          rows="2"
                          maxRows="4"
                          name="formData.securityInformation.cyberSecurityChallenge"
                        />
                      </div>
                      <div className={styles.securitydesc}>
                        <span className={styles.prodetail}>
                          What are your current security measures?
                        </span>
                        <FormControl
                          control="Textarea"
                          placeholder=""
                          rows="2"
                          maxRows="4"
                          name="formData.securityInformation.securityMeasure"
                        />
                      </div>
                      <div className={styles.securitydesc}>
                        <span className={styles.prodetail}>
                          Past experience in cybersecurity incidents or
                          breaches?
                        </span>
                        <FormControl
                          control="Textarea"
                          placeholder=""
                          rows="2"
                          maxRows="4"
                          name="formData.securityInformation.complianceRequirement"
                        />
                      </div>
                      <div className={styles.securitydesc}>
                        <span className={styles.prodetail}>
                          Do you need to be compliant with any specific
                          cybersecurity standards?
                        </span>
                        <FormControl
                          control="Textarea"
                          placeholder=""
                          rows="2"
                          maxRows="4"
                          name="formData.securityInformation.pastExperiences"
                        />
                      </div>
                    </div>
                  </div> */}
              </div>

              <div className={styles.cstdetail}>
                <Submitbtn buttonText="Done" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Profileinfo;
