import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";

function SelectBox(props) {
  const { name, prevalue, placeholder, options, disabled, selected, ...rest } = props;
  const selectedOption = options.find((option) => option.id == prevalue);

  return (
    <div>
      <Field name={name}>
        {({ field, form }) => (
          <div className="row">
            <div className="text_left">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "black" : "black",
                    borderWidth: "1px",
                    boxShadow: state.isFocused ? "0 0 0 1px black" : "none",
                  }),
                }}
                onChange={(res) => {
                  const selectedValue = res ? res.id : null;
                  const selectedlabel = res ? res.label : null;
                  form.setFieldValue(name, selectedValue,selectedlabel);
                  {
                    selected &&  selected(selectedValue)
                  }
                 
                }}
                placeholder={placeholder}
                options={options}
                value={selectedOption}

                isClearable={true}
                isDisabled={disabled}
              />
              <ErrorMessage name={name} />
            </div>
          </div>
        )}
      </Field>
    </div>
  );
}

export default SelectBox;
