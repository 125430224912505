import React from "react";
import styles from "../formassistant/formassistant.module.css";
import { Formik, Form } from "formik";
import FormControl from "../controls/formcontrol";

const data = [
  {
    id: 1,
    title: "regulatory compliance and reporting"
  },
  {
    id: 2,
    title: "data encryption and data loss prevention"
  },
  {
    id: 3,
    title: "vulnerability assessments and penetration testing"
  },
  {
    id: 4,
    title: "developing or improving your cybersecurity procedures"
  },
  {
    id: 5,
    title: "identity and access management solutions"
  },
  {
    id: 6,
    title: "threat hunting and proactive security measures"
  },
  {
    id: 7,
    title: "secure network architecture design and implementation"
  },
  {
    id: 8,
    title: "employee cybersecurity training and awareness programs"
  },
];

const index = () => {
  return (
    <div className="overviewback">
      <Formik>
        <Form>
          <div>
            <h3 className={styles.assishead}>Do you need assistance with/ Are you interested in</h3>
          </div>
          <div class="grid grid-rows-4 grid-flow-col gap-4">
          {data.map((assistserve) => (
            <div key={assistserve.id} className={styles.assisform}>
              <div>
                <FormControl control="Checkbox" className={styles.assischeck} />
              </div>
              <div>
                <span className={styles.assisdesc}>{assistserve.title}</span>
              </div>
            </div>
            ))}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default index;
