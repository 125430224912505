import axios from "axios";
import {
  LOGIN,
  CREATECUSTOMER,
  GETCATEGORYSERVICE,
  GETCUSTOMERONE,
  GETSERVICE,
  GETOWNSERVICE,
  GETOWNSERVICEONE,
  SENDINVITE,
  GETREVIEW,
  CREATEREVIEW,
  CARTADD,
  CARTGET,
  CARTDELTE,
  PURCHASE,
  CHECKOUT,
  UPDATERACI,
  GETCOMMENTONE,
  UPDATEPROFILE,
  GETPROFILE,
  CUSTOMERUPDATE,
  GETDOCUMENT,
  CREATESUBSCRPTION,
  GETSUBSCRPTION,
  CANCELSUBSCRPTION,
  GETADDONS,
  CHECKOUTAPIS
} from "./routes";
export const BASE_URL = "https://api.fncyber.com/";

axios.defaults.baseURL = BASE_URL;

export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const SendInvite = async (data) => {
  try {
    const response = await axios.post(SENDINVITE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const CustomerCreate = async (data) => {
  try {
    const response = await axios.post(CREATECUSTOMER, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetServiceApi = async (data) => {
  try {
    const response = await axios.get(GETCATEGORYSERVICE, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetPatServiceApi = async (id) => {
  try {
    const response = await axios.get(`${GETCATEGORYSERVICE}/${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetCustomerApi = async (id) => {
  try {
    const response = await axios.get(`${GETCUSTOMERONE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETSERVICEApi = async (id) => {
  try {
    const response = await axios.get(`${GETSERVICE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETOWNSERVICEApi = async (id) => {
  try {
    const response = await axios.get(`${GETOWNSERVICE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETOWNSERVICEONEAPI = async (id) => {
  try {
    const response = await axios.get(`${GETOWNSERVICEONE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETREVIEWAPI = async (id) => {
  try {
    const response = await axios.get(`${GETREVIEW}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CREATEREVIEWAPI = async (data) => {
  try {
    const response = await axios.post(`${CREATEREVIEW}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CARTADDAPI = async (data) => {
  try {
    const response = await axios.post(`${CARTADD}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CARTGETAPI = async (id) => {
  try {
    const response = await axios.get(`${CARTGET}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CARTDELTEAPI = async (id) => {
  try {
    const response = await axios.delete(`${CARTDELTE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PURCHASEAPI = async (data) => {
  try {
    const response = await axios.post(`${PURCHASE}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CHECKOUTAPI = async (data) => {
  try {
    const response = await axios.post(`${CHECKOUT}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CHECKOUTAPISS = async (data) => {
  try {
    const response = await axios.post(`${CHECKOUTAPIS}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATERACIAPI = async (data) => {
  try {
    const response = await axios.post(`${UPDATERACI}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETCOMMENTONEAPI = async (id) => {
  try {
    const response = await axios.get(`${GETCOMMENTONE}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const UPDATEPROFILEAPI = async (data) => {
  try {
    const response = await axios.post(`${UPDATEPROFILE}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETPROFILEAPI = async () => {
  try {
    const response = await axios.get(`${GETPROFILE}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETADDONSAPI = async () => {
  try {
    const response = await axios.get(`${GETADDONS}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CUSTOMERUPDATEAPI = async (data) => {
  try {
    const response = await axios.put(`${CUSTOMERUPDATE}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GETDOCUMENTAPI = async (id) => {
  try {
    const response = await axios.get(`${GETDOCUMENT}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CREATESUBSCRPTIONAPI = async (data) => {
  try {
    const response = await axios.post(`${CREATESUBSCRPTION}`, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GETSUBSCRPTIONAPI = async (id) => {
  try {
    const response = await axios.get(`${GETSUBSCRPTION}${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const CANCELSUBSCRPTIONAPI = async (data) => {
  try {
    const response = await axios.delete(`${CANCELSUBSCRPTION}${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const EmployeeCreate = async (data) => {
//   try {
//     const response = await axios.post(EMPLOYEECREATE, data, {
//       headers: { "Content-Type": "application/json" },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const EmployeeGetAll = async (data) => {
//   try {
//     const response = await axios.get(ALLEMPLOYEEGET, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const EmployeeDelete = async (id) => {
//   try {
//     const response = await axios.delete(`${EMPLOYEEDELETE}${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };

// export const ServiceAdd = async (data) => {
//   try {
//     const response = await axios.post(SERVICEADD, data, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const ServiceGet = async (data) => {
//   try {
//     const response = await axios.get(SERVICEBYCATEGORY, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const DocumentAdd = async (data) => {
//   try {
//     const response = await axios.post(DOCUMENTCREATE, data, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const LinkAdd = async (data) => {
//   try {
//     const response = await axios.post(LinkCREATE, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const CustomerALLGet = async () => {
//   try {
//     const response = await axios.get(GETCUSTOMERALL, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const ServiceALLGet = async () => {
//   try {
//     const response = await axios.get(SERVICEALL, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };

// export const ServiceOneGet = async (id) => {
//   try {
//     const response = await axios.get(`${SERVICEONE}/${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const CustomerGet = async (id) => {
//   try {
//     const response = await axios.get(`${GETCUSTOMER}/${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
// export const LinkDeleteAPi = async (id) => {
//   try {
//     const response = await axios.delete(`${LinkDelete}${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
