import React from "react";
import Login from "../../Components/Common/Log";

const index = () => {
  return (
    <div>
      <Login />
    </div>
  );
};
export default index;
