import React from "react";
import { useNavigate } from "react-router-dom";

function Error() {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const hanlde = () => {
    navigate("/");
  };
  return (
    <div>
      {" "}
      <div className="flex justify-center items-center">
        {queryParams && <h1>Payment UnSuccessfull</h1>}
        <button onClick={hanlde}>Contact to Admin</button>
      </div>
    </div>
  );
}

export default Error;
