import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layouts from "./Layout/index";
import Login from "../Pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import { gapi } from "gapi-script";
import AuthContext from "../Utils/auth-context";
import Billing from "../Pages/Billing";
import Billing2 from "../Pages/BillingForm";
import NotFound from "../Pages/NotFound";

const App = () => {
  const authCtx = useContext(AuthContext);
  // const navigate = useNavigate();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "517363405103-sisvi7nn35kinlk8rq8uh8kojgj515s1.apps.googleusercontent.com",
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });
  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* <Layouts /> */}

      <Routes>
        <Route path="/login/:id?/:plan?" element={<Login />} />

        {authCtx.isLoggedIn ? ( 
          <>
            <Route path="/*" element={<Layouts />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/billingform" element={<Billing2 />} />
             {/* <Route   path="*" element={<NotFound />} /> */}
            {/* <Route   path="/not-found" element={<NotFound />} /> */}
          </>
        ) : (
          <Route path="/login/:id?/:plan?" element={<Login />} />
        )}

        <Route
          path="*"
          element={<Navigate to={authCtx.isLoggedIn ? "/" : "/login"} />}
        />
      </Routes>
    </div>
  );
};

export default App;
