import React from 'react';
import styles from '../reviewbutton/reviewbutton.module.css';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const Review = ({ buttonText }) => {
  return (
    <>
    
     <div className={styles.mainreviewbtn}>
     <Link to="/">
       <button className={styles.reviewbtn} >
         <span className={styles.reviewbtndesc}>{buttonText}</span>
       </button>
       </Link>
    </div>
    </>
  );
};

Review.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default Review;
