import React from 'react'
import styles from "../reviewbutton/reviewbutton.module.css";
import Downloadimg from "../../../Assets/images/download.png";
import PropTypes from "prop-types";
const index = ({buttonText, onClick, type, imageSrc, disabled}) => {
  return (
    <div className={styles.mainreviewbtn}>
    <button className={styles.reviewbtn} onClick={onClick} type={type} disabled={disabled}>
      <span className={styles.reviewbtndesc}>{buttonText}</span>
      <img src={Downloadimg} alt="" className={styles.downimg} />
    </button>
  </div>
  )
}

export default index;
