import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { CHECKOUTAPI } from "../../Utils/services";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function IntegratedCheckout() {
  const [transactionClientSecret, setTransactionClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { state } = useLocation();


  const onCustomerNameChange = (ev) => {
    setName(ev.target.value);
  };

  const onCustomerEmailChange = (ev) => {
    setEmail(ev.target.value);
  };

  useEffect(() => {
    setStripePromise(
      loadStripe(
        "pk_test_51HSiLbET2Bf43bMhNvLwm181yCS168wke2StQE6kvOZxD8A7Cjn3M7OiFatYPcbcMq9UsjKDLosqMF912dlttY3000dsU3f14G"
      )
    );
  }, []);

  const createTransactionSecret = async () => {
    const data = {
      cartId: state.cartId,
      customerId: "66f29f8b-cf35-45d2-b782-5070144fba15",
    };
    try {
      const result = await CHECKOUTAPI(data);
      if (result.res.status === true) {
        setTransactionClientSecret(result.res.data);
      } else {
      }
    } catch (error) {
      toast.error(error || error?.message);
    }
  };
  const initiatePayment = async () => {
    const data = {
      cartId: state.cartId,
      customerId: "66f29f8b-cf35-45d2-b782-5070144fba15",
    };
    try {
      const response = await CHECKOUTAPI(data);
      if (response.res.status === true) {
        window.location.href = response?.res?.data;
      }
      // Assuming the response contains the URL where you want to redirect
    } catch (error) {
      toast.error(error || error?.message);
      // Handle the error here
    }
  };
  return (
    <>
      <button onClick={initiatePayment} colorScheme="green">
        Initiate Payment
      </button>
      {transactionClientSecret === "" ? (
        <></>
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: transactionClientSecret }}
        >
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/success",
      },
    });
   
    if (result.error) {
     
    }
  };

  return (
    <>
      <PaymentElement />

      <button disabled={!stripe} onClick={handleSubmit}>
        Pay
      </button>
    </>
  );
};

export default IntegratedCheckout;
