import React from 'react';
import AllService from "../../../Components/Common/ServiceList"



const AllServices = () => {
  return (
    <div>
      <AllService />
    </div>
  )
}

export default AllServices
