import React from "react";
import Input from "../inputs/defaultinput";
// import UploadFile from "../DropZone";
// import SelectBox from "../SelectBox";
// import Heading from "../Heading";
// import SearchInput from "../SearchInput";
// import DynmaicTable from "../../Controls/Tables/DynmaicTable";
// import DynamicTable2 from "../../Controls/Tables/DynamicTable2"
import Checkbox from "../checkbox";
import Rating from "../../ratings";
import TextArea from "../inputs/textarea";
import PhoneNumber from "../phonenumber";
import Selectbox from "../selectbox";
import Heading from "../Heading";

const FormControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "Input":
      return <Input {...rest} />;

    case "Checkbox":
      return <Checkbox {...rest} />;
    case "Rating":
      return <Rating {...rest} />;
    case "heading":
      return <Heading {...rest} />;

    // case "UploadFile":
    //   return <UploadFile {...rest} />;

    case "Select":
      return <Selectbox {...rest} />;

    // case "heading":
    //   return <Heading {...rest} />;

    // case "Search":
    //   return <SearchInput {...rest} />;
    case "Phone":
      return <PhoneNumber {...rest} />;

    case "Textarea":
      return <TextArea {...rest} />;

    // case "DynmaicTable":
    //   return <DynmaicTable {...rest} />;

    // case "DynmaicTable2":
    //   return <DynamicTable2 {...rest} />;
    default:
      return null;
  }
};

export default FormControl;
