import React from "react";
import {createButton} from "react-social-login-buttons";
import { FcGoogle } from 'react-icons/fc';

const config = {
  text: "Sign in with Google",
  icon: FcGoogle,
  iconSize :"45px",
  style: { background: "#FFF",color:'#292728', padding:'30px 80px' ,border:'1px solid #707070', borderRadius:'10px'},
};
/** My Facebook login button. */
const   Index = createButton(config);

export default Index;