import React, { useEffect, useState } from "react";
import styles from "../Purchase/purchaseservice.module.css";
import deleteimg from "../../../Assets/images/delete.png";
import download from "../../../Assets/images/download.png";
import cost from "../../../Assets/images/cost.png";
import duration from "../../../Assets/images/watch.png";
import Mainbtn from "../../../Components/Common/button";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CARTDELTEAPI, CARTGETAPI, CHECKOUTAPISS } from "../../../Utils/services";
import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";
import * as Yup from 'yup';

const Purchase = () => {
  const [cardItems, setCardItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const company_id = JSON.parse(localStorage.getItem("user"))?.companyId;
  const userId = JSON.parse(localStorage.getItem("user"))?.userId;
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const result = await CARTGETAPI(company_id);
      if (result && result?.res.status === true) {
        setCardItems(result?.res?.data);
      }
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const totalAmount = cardItems?.cartItemResponseList?.reduce(
    (total, item) => total + item.price,
    0
  );

  const handle = async (values) => { 
    if (cardItems.cartId && userId && Object.values(values.checkboxes).every(Boolean)) { 
      setLoading(true);
      const data = {
        cartId: cardItems.cartId,
        customerId: userId,
        companyId: company_id,
      };
      try {
        const response = await CHECKOUTAPISS(data);
        if (response?.res?.status) {
          window.open(response.res.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error.message || error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please enable all checkboxes to proceed");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await CARTDELTEAPI(id);
      if (result.res.status === true) {
        toast.error(result.res.message);
        fetchData();
      }
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  // Generate validation schema dynamically
  const validationSchema = Yup.object().shape({
    checkboxes: Yup.object().shape(
      cardItems?.cartItemResponseList?.reduce((acc, item) => {
        acc[item.id] = Yup.boolean().oneOf([true], 'This checkbox must be checked');
        return acc;
      }, {})
    ),
  });

  // Generate initial values dynamically
  const initialValues = {
    checkboxes: cardItems?.cartItemResponseList?.reduce((acc, item) => {
      acc[item.id] = false; // Default to unchecked
      return acc;
    }, {}),
  };

  return (
    <div className="overview">
      <Loader loading={loading} />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handle}
      >
        {formik => (
          <Form>
            <div>
              <h2 className={styles.purchasehead}>Purchase Summary</h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className={styles.puchasesum}>
                  <div>
                    <div>
                      {cardItems?.cartItemResponseList?.map((purchaseserve) => (
                        <div
                          key={purchaseserve.id}
                          className={styles.purchaseservice}
                        >
                          <div className={styles.purchasetile}>
                            <div>
                              <h3 className={styles.prservicename}>
                                {purchaseserve.serviceName}
                              </h3>
                            </div>
                            <div className={styles.puchaseimg}>
                              <div className={styles.purchasedown}>
                                <img src={download} alt="" />
                              </div>
                              <div className={styles.purchasedel} onClick={() => handleDelete(purchaseserve.cartItemId)}>
                                <img src={deleteimg} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className={styles.purchasecos}>
                            <div className={styles.tilepurchasecos}>
                              <div className={styles.purchasecosimg}>
                                <img src={cost} alt="" />
                              </div>
                              <div className={styles.tilepurchasedesc}>
                                <span>{purchaseserve.price}</span>
                              </div>
                            </div>
                            <div className={styles.tilespurchasecos}>
                              <div className={styles.purchasecosimg}>
                                <img src={duration} alt="" />
                              </div>
                              <div className={styles.tilepurchasedesc}>
                                <span>{purchaseserve.duration} <span>Weeks</span></span>
                              </div>
                            </div>
                          </div>
                          <div className={styles.servetermcheck}>
                            <div>
                              <Field type="checkbox" name={`checkboxes.${purchaseserve.id}`} />
                              <ErrorMessage    style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}  name={`checkboxes.${purchaseserve.id}`} component="div" className="error" />
                            </div>
                            <div>
                              <span className={styles.tiledesc}>
                                I have read and agree to the terms presented in the{" "}
                                <span className={styles.tiledescclr}>
                                  Terms and Conditions
                                </span>{" "}
                                agreement.
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.pucrchasecard}>
                  <div className={styles.serveslist}>
                    {cardItems?.cartItemResponseList &&
                      cardItems?.cartItemResponseList?.map((serviclist) => (
                        <div
                          key={serviclist.id}
                          className={styles.purchaseservicelist}
                        >
                          <div>
                            <span className={styles.selectedsevice}>
                              {serviclist.serviceName}
                            </span>
                          </div>
                          <div>
                            <span className={styles.selectedsevice}>
                              {serviclist.price}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className={styles.prtotal}>
                    <div>
                      <span className={styles.sevicettl}>Total Purchase</span>
                    </div>
                    <div>
                      <span className={styles.sevicettl}>{totalAmount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.purchasecheckout}>
              <Mainbtn buttonText="Checkout" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Purchase;
