import overviewimg from "../../Assets/images/Overview.svg";
import myservice from "../../Assets/images/My_service.svg";
import profile from "../../Assets/images/profile.svg";
import servicewizard from "../../Assets/images/wizard.svg";
import overviewblack from "../../Assets/images/OverviewBlack.svg";
import myserviceblcak from "../../Assets/images/My_serviceblack.svg";
import profileblack from "../../Assets/images/profileblack.svg";
import servicewizardblack from "../../Assets/images/wizardblack.svg";
import Plan_black from "../../Assets/images/Plan_black.svg";
import Plan from "../../Assets/images/Plan.svg";

const SIDEBAR = [
  {
    id: 1,
    label: "Overview",
    ref: "dash",
    images: overviewblack,
    clrimages: overviewimg,
    path: "/",
  },
  {
    id: 2,
    label: "My Services",
    ref: "dash",
    images: myserviceblcak,
    clrimages: myservice,
    path: "/services",
  },
  {
    id: 3,
    label: "Profile",
    ref: "dash",
    images: profileblack,
    clrimages: profile,
    path: "/profile",
  },
  {
    id: 4,
    label: "Service Wizard",
    ref: "dash",
    images: servicewizardblack,
    clrimages: servicewizard,
    path: "/servicewizard",
  },

  {
    id: 4,
    label: "Plan Details",
    ref: "dash",
    images: Plan_black,
    clrimages: Plan,
    path: "/plandetails",
  },
];

export default SIDEBAR;
