import React from "react";
import { Link } from "react-router-dom";
import Progressbar from "../progressbar";
import Mainbtn from "../button";
import message from "../../../Assets/images/comment.png";
import download from "../../../Assets/images/download.png";
import styles from "../Ongoingservice/ongoingservice.module.css";

const data = [
  {
    id: 1,
    name: "Service name:",
    title: "Sec. Analysts name:",
    desc: "Status of service",
  },
  {
    id: 2,
    name: "Service name:",
    title: "Sec. Analysts name:",
    desc: "Status of service",
  },
  {
    id: 3,
    name: "Service name:",
    title: "Sec. Analysts name:",
    desc: "Status of service",
  },
  {
    id: 4,
    name: "Service name:",
    title: "Sec. Analysts name:",
    desc: "Status of service",
  },
  {
    id: 5,
    name: "Service name:",
    title: "Sec. Analysts name:",
    desc: "Status of service",
  },
];

const Ongoingservice = (props) => {
  const { data } = props;

  return (
    <div className="">
      {/* <div className="">
        <h2 className={styles.overviewhead}>Hello, name</h2>
      </div> */}
      <div className={styles.headservicetile}>
        <h3>Ongoing Services ({data?.length})</h3>
      </div>
      <div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
        {data?.map((ongoservice) => 
        
        (
          <div key={ongoservice.id} className={styles.ongoservicetile}>
            <div className={styles.servicetile}>
              <div className={styles.servicedescdetail}>
                <h4 className={styles.servicename}>
                  {ongoservice.serviceName}
                </h4>
              </div>
              <div className={styles.imgongo}>
                <img src={message} alt="" />
                <img src={download} alt="" />
              </div>
            </div>
            <div className={styles.analystname}>
              <span>{ongoservice.serviceName}</span>
            </div>
            <Progressbar maxCompleted={ongoservice?.totalStatus} completed={ongoservice?.completedStatus} />
            <div className={styles.servestatus}>
              <span>{ongoservice.desc}</span>
            </div>
            {
              ongoservice?.startDate === null ?
              <div className={styles.ongobtn}>
              <Link  to={`/userupdate/${ongoservice?.purchasetaskId}`} key={ongoservice?.purchasetaskId}>
                {/* <Mainbtn buttonText="Update RACI Matix" /> */}
                <Mainbtn buttonText="Manage Users" disabled={true}/>
              </Link>
            </div> : 
            <div className={styles.ongobtn}>
            <Link  to={`/userupdate/${ongoservice?.purchasetaskId}`} key={ongoservice?.purchasetaskId}>
              {/* <Mainbtn buttonText="Update RACI Matix" /> */}
              <Mainbtn buttonText="Manage Users" />
            </Link>
          </div>
            }
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ongoingservice;
