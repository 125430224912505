import React from 'react';
import { Field } from 'formik';


function Checkbox(props) {
    const { name, isChecked, value, label, ...rest } = props;

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div className='d-flex'>
                            <input
                                type="checkbox"
                                id={name}
                                checked={isChecked}
                                {...field}
                                {...rest}
                            />
                            <label htmlFor={name} className='mx-2'>
                                {label}
                            </label>
                           
                        </div>
                    </div>
                )}
            </Field>
        </div>
    )
}

export default Checkbox;
