import React, { useContext, useState } from "react";
import styles from "./usertable.module.css";
import Loader from "../../Loader";
import { CustomerCreate, GetCustomerApi, UPDATEPROFILEAPI, GETPROFILEAPI, CUSTOMERUPDATEAPI } from "../../../../Utils/services";
import AuthContext from "../../../../Utils/auth-context";
import { useEffect } from "react";


const UserTable = () => {
    const authCtx = useContext(AuthContext);
    const [userData, SetUserData] = useState();
    const [loading, setloading] = useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [CompanyData, SetCompanyData] = useState({});

    const fetchData = async () => {
        setloading(true);
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx?.user)) {
            const data = JSON?.parse(authCtx?.user);
            const parsedUserData = JSON.parse(authCtx.user);

            try {
                const result = await GetCustomerApi(data?.companyId);
                if (result && result?.res.status === true) {
                    if (isJSONString(authCtx.user)) {
                        // Merge allData and parsedUserData
                        const mergedData = { ...result?.res?.data, ...parsedUserData };
                        SetUserData(mergedData);
                        setloading(false);
                    } else {
                        SetUserData(parsedUserData);
                        setloading(false);
                    }
                } else {
                    SetUserData(parsedUserData);
                    setloading(false);
                }
            } catch (error) {
                setloading(false);
                SetUserData(parsedUserData);
            }
        } else {
            const data = authCtx?.user;
            const parsedUserData = authCtx.user;
            try {
                const result = await GetCustomerApi(data?.companyId);
                if (result && result?.res.status === true) {
                    // Merge allData and parsedUserData
                    const mergedData = { ...result?.res?.data, ...parsedUserData };
                    SetUserData(mergedData);
                    setloading(false);
                } else {
                    SetUserData(parsedUserData);
                    setloading(false);
                }
            } catch (error) {
                setloading(false);
                SetUserData(parsedUserData);
                // Fallback to test data
            }
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    

    return (
        <>
            <Loader loading={loading} />
            <div>
                <div className="">
                    <div className="col-lg-12 mt-5 text_left">
                        {/* <div>
                            <h3>RACI Matix</h3>
                            <span>Stakeholders required to be involved in the RBIA.</span>
                        </div> */}
                        <div className=" mt-3 custom_table text-center w-100">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>Sno.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Profile</th>
                                        <th>Contact</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData && userData?.customerProfileList?.map((row, index) => { 
                                        // console.log(row,"row")
                                        // Skip the record if row?.ownerName does not exist
                                        // if (!row?.ownerName) {
                                        //     return null;
                                        // }

                                        return (
                                            <tr key={index}>
                                                <td className={styles.alluserno}>
                                                    {" "}
                                                    <div>{index + 1}</div>
                                                </td>
                                                <td>
                                                    {" "}
                                                    <div>{row?.ownerName ? row?.ownerName : "User"}</div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {row?.ownerEmail}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.alluserimg}>
                                                        {row?.imageUrl && <img src={row?.imageUrl} alt="Profile" style={{width: "30px"}} />}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.alluserimg}>
                                                        {row?.ownerMobileNo}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default UserTable;
