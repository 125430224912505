import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import servisimg from "../../../../src/Assets/images/Rectangle .png";
import Mainbtn from "../../../Components/Common/reviewbutton";
import styles from "../ServiceList/allservice.module.css";
import { useEffect } from "react";
import { GetPatServiceApi, GetServiceApi } from "../../../Utils/services";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../Loader";


const ServiceList = () => {
    const [serviceList, setServiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    let { state } = useLocation();



    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await GetPatServiceApi(state?.id);
            if (result && result?.res.status === true) {
                setLoading(false);
                setServiceList(result.res.data);
            }
            else {

            }
        } catch (error) {
            setLoading(false);
            // Fallback to test data
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                serviceList
                    ?.filter(result => result?.categoryName === state?.slug)
                    ?.map((result) => {

                        return (
                            <div className={styles.allserve} key={result?.categoryId}>
                                <div className={styles.allmainserve}>
                                    <div className={styles.cardhead}>
                                        <h2 className={styles.allservehead}>{state?.slug}</h2>
                                    </div>
                                    <div className={styles.servecard}>
                                        <div className={styles.servelist}>
                                            <div className="row m-0">
                                                {result
                                                    ?.categoryServices
                                                    ?.map((allservice, index) => (
                                                        <div className="col-lg-6" key={allservice.taskId}>
                                                            <div className={styles.mainservice}>
                                                                <div className={styles.squaresmall}>
                                                                    <div>{index + 1}</div>
                                                                </div>
                                                                <Link to={`/services/servicedetail/${allservice?.taskId}`}>
                                                                    <div className={styles.allservetext}>
                                                                        <span>{allservice.taskName}</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
            }
            <Loader loading={loading} />
            <div>

                <div className="col-lg-12">
                    <div className={styles.servicehelp}>
                        <div className={styles.searchservice}>
                            <div>
                                <span className={styles.searchservicetext}>
                                    Not sure what you are looking for?
                                    <br /> Service wizard can help you!
                                </span>
                                <Link to="/servicewizard">
                                    <Mainbtn buttonText="Service wizard" />
                                </Link>
                            </div>
                            <div style={{ width: "300px" }}>
                                <img src={servisimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ServiceList;