import React from "react";
import { Field, ErrorMessage } from "formik";
// import TextError from "../../Errros/index"

function TextArea(props) {
  const { name, type, cols, rows, placeholder, ...rest } = props;
  return (
    <div>
      <Field name={name} cols={cols} rows={rows}>
        {({ field, form }) => (
          <div className="row">
            <div>
              <textarea
                name={name}
                cols={cols}
                rows={rows}
                type={type}
                placeholder={placeholder}
                {...field}
                {...rest}
              ></textarea>
              <div className="colorred">
                <ErrorMessage name={name} />
              </div>

            </div>
          </div>
        )}
      </Field>
    </div>
  );
}

export default TextArea;
