import React, { useState, Children, cloneElement, useEffect } from 'react';
import SubTitle from '../controls/Heading/SubTitle';

export function CustomTabs({ children, onChange, activeTab, setActiveTab }) {
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        onChange && onChange(activeTab);
    }, [activeTab, onChange]);

    return (
        <div className=''>
            <div className="d-flex align-items-center">
                {Children.map(children, (child, index) => (
                    child ? (
                        <div
                            key={index} SS
                            className={`custom-tab ${index === activeTab ? 'active-settings cursor-pointer me-4' : 'cursor-pointer me-4'}`}
                            onClick={() => handleTabClick(index)}
                        >
                            <SubTitle title={child.props.tabLabel} />
                        </div>
                    ) : null
                ))}
            </div>
            <div className="custom-tabs-content">
                {Children.map(children, (child, index) => (
                    child ? (
                        cloneElement(child, { active: index === activeTab })
                    ) : null
                ))}
            </div>
        </div>
    );
}

export function CustomTabPanel({ active, children }) {
    return active ? children : null;
}
