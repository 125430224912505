import React, { useEffect, useState } from 'react'

function Subscription({ id }) {
    const [options, setoption] = useState(
        [
            {
                id: 1,
                label: 'Essentials',
                value: 'Essentials',
                Data: [
                    {
                        "id": 1,
                        "label": "Service Type",
                        "value": "Self-Service"
                    },
                    {
                        "id": 2,
                        "label": "Collaboration via",
                        "value": "Emails"
                    },
                    {
                        "id": 3,
                        "label": "Response Time",
                        "value": "Response within 48 hrs [Mon-Fri]"
                    },
                    {
                        "id": 4,
                        "label": "Security - Technical Assistance Level",
                        "value": "Competent"
                    },
                    {
                        "id": 5,
                        "label": "Problem Elicitation",
                        "value": "Best Effort"
                    },
                    {
                        "id": 6,
                        "label": "Initiatives Driven by",
                        "value": "Customer"
                    },
                    {
                        "id": 7,
                        "label": "Issue Remedy",
                        "value": "Best Effort & Reasoning"
                    },
                    {
                        "id": 8,
                        "label": "Cybersecurity Best Practices & Controls",
                        "value": "Best Fit"
                    },
                    {
                        "id": 9,
                        "label": "Current State Assessment",
                        "value": "-"
                    },
                    {
                        "id": 10,
                        "label": "Solution Detailing",
                        "value": "-"
                    },
                    {
                        "id": 11,
                        "label": "Project Management Methodology",
                        "value": "_"
                    },
                    {
                        "id": 12,
                        "label": "SecOps & IR Management Assistance Level",
                        "value": "-"
                    },
                    {
                        "id": 13,
                        "label": "Standard Operating Procedure",
                        "value": "-"
                    },
                    {
                        "id": 14,
                        "label": "Cybersecurity Maturity Program",
                        "value": "-"
                    },
                    {
                        "id": 15,
                        "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                        "value": "-"
                    },
                    {
                        "id": 16,
                        "label": "Breach Response Management - Readiness Assistance",
                        "value": "-"
                    }
                ]



            },
            {
                id: 2,
                label: 'Operational',
                value: 'Operational',
                Data:
                    [
                        {
                            "label": "Cybersecurity Best Practices & Controls",
                            "value": "Administered"
                        },
                        {
                            "label": "Current State Assessment",
                            "value": "After signup - Customers are given access to a CRM self-service; support is managed via tickets."
                        },
                        {
                            "label": "Solution Detailing",
                            "value": "Based on [6], solution details would include recommended specifications and implementation guidelines only"
                        },
                        {
                            "label": "Project Management Methodology",
                            "value": "Guided Assistance to draw a high-level project plan [plan of action] to achieve the desired goal based on [6] - Project Management Effort is an additional service and has to be procured as a specific Add-on service and Is-Not Applicable for this plan"
                        },
                        {
                            "label": "SecOps & IR Management Assistance Level",
                            "value": "The assigned consultant will have competent ability, knowledge, and skill to accomplish the goal successfully and satisfactorily."
                        },
                        {
                            "label": "Standard Operating Procedure",
                            "value": "SOPs are custom developed and supported with Procedural Guidance"
                        },
                        {
                            "label": "Cybersecurity Maturity Program",
                            "value": "Based on Current state assessment questionnaire, Probing Interviews, Focus Groups"
                        },
                        {
                            "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                            "value": "Based on [2], [3], and [4], the proposed remedy is ideal for fixing temporary issues/requirements only and would require additional elicitation for extended applicability."
                        },
                        {
                            "label": "Breach Response Management - Readiness Assistance",
                            "value": "Best Fit"
                        }
                    ]




            },
            {
                id: 3,
                label: 'Tactical',
                value: 'Tactical',
                Data:
                    [
                        {
                            "label": "Service Type",
                            "value": "Managed"
                        },
                        {
                            "label": "Collaboration via",
                            "value": "After signup - Customers are given access to a CRM self-service; support is managed via tickets."
                        },
                        {
                            "label": "Response Time",
                            "value": "Guaranteed response within 24 hrs"
                        },
                        {
                            "label": "Security - Technical Assistance Level",
                            "value": "Expert & Authoritative"
                        },
                        {
                            "label": "Problem Elicitation",
                            "value": "5 - (Why-Because)s - with all required stakeholders involved based on RACI"
                        },
                        {
                            "label": "Initiatives Driven by",
                            "value": "RACI based - Joint responsibility and collaboration; joint facilitation of tasks and interaction across stakeholder groups; subjected to all Customer Organizational Constraints [Schedule, Cost, Resources] - Accountability rests with the Customer"
                        },
                        {
                            "label": "Issue Remedy",
                            "value": "Based on [2], [3], and [4], the proposed remedy is suitable for any ongoing operational issues/requirements and is expected to bring stability and consistency to the Enterprise Environment for the short term only and would require additional elicitation for strategic applicability."
                        },
                        {
                            "label": "Cybersecurity Best Practices & Controls",
                            "value": "Technology Driven"
                        },
                        {
                            "label": "Current State Assessment",
                            "value": "\"Non-Intrusive, No-Cost, Assessment based on 7-days of monitoring - Customer Authorization required - sample attached https://www.fortinet.com/ctap\""
                        },
                        {
                            "label": "Solution Detailing",
                            "value": "Based on [6], the outcome is a detailed Design Document along with relevant components, specifications, recommended configurations based on CTAP along with testing, release, and change management procedures"
                        },
                        {
                            "label": "Project Management Methodology",
                            "value": "Planned in Sprints, 2-4 weeks each,  deliverables spanned out accordingly based on [6] - Project Management Effort is an additional service and has to be procured as a specific Add-on service."
                        },
                        {
                            "label": "SecOps & IR Management Assistance Level",
                            "value": "The assigned consultant will have competent ability, knowledge, and skill to accomplish the goal successfully and satisfactorily."
                        },
                        {
                            "label": "Standard Operating Procedure",
                            "value": "SOPs are custom developed and supported with Procedural Guidance"
                        },
                        {
                            "label": "Cybersecurity Maturity Program",
                            "value": "_"
                        },
                        {
                            "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                            "value": "_"
                        },
                        {
                            "label": "Breach Response Management - Readiness Assistance",
                            "value": "_"
                        }
                    ]





            },
            {
                id: 4,
                label: 'Strategic',
                value: 'Strategic',
                Data: [
                    {
                        "label": "Service Type",
                        "value": "Managed"
                    },
                    {
                        "label": "Collaboration via",
                        "value": "After signup - Customers are given access to a CRM self-service; support is managed via tickets."
                    },
                    {
                        "label": "Response Time",
                        "value": "Guaranteed response within 8 hrs"
                    },
                    {
                        "label": "Security - Technical Assistance Level",
                        "value": "Expert & Authoritative"
                    },
                    {
                        "label": "Problem Elicitation",
                        "value": "Detailed Root Cause Analysis and/or Failure Mode-Effects Analysis - with all required stakeholders involved based on RACI:"
                    },
                    {
                        "label": "Initiatives Driven by",
                        "value": "RACI based - fnCyber owns the responsibility, facilitates collaboration, and drives the Objective; subjected to all Customer Organizational Constraints [Schedule, Cost, Resources] - Accountability rests with the Customer"
                    },
                    {
                        "label": "Issue Remedy",
                        "value": "Based on [2], [3], and [4], the proposed remedy is suitable for a permanent long-term strategic initiative; deployment of such solutions might extend beyond a year; customers are advised to lock in support from fnCyber for continuity purposes."
                    },
                    {
                        "label": "Cybersecurity Best Practices & Controls",
                        "value": "Industry Best"
                    },
                    {
                        "label": "Current State Assessment",
                        "value": "\"Non-Intrusive, No-Cost, Assessment based on 7-days of monitoring - Customer Authorization required - sample attached https://www.fortinet.com/ctap\""
                    },
                    {
                        "label": "Solution Detailing",
                        "value": "Based on [6], the outcome is a detailed Architectural and Engineering Document that may comprise multiple designs within consisting of an elaborated phased deployment and operational plan."
                    },
                    {
                        "label": "Project Management Methodology",
                        "value": "Execution is based on Disciplined Agile Practices with incremental, iterative, and continuous improvement approaches - Project Management Effort is an additional service and has to be procured as a specific Add-on Service."
                    },
                    {
                        "label": "SecOps & IR Management Assistance Level",
                        "value": "The assigned consultant will have the in-depth ability, domain expertise, highly talented, and experienced to accomplish the goal successfully and deliver the overall value and ROI."
                    },
                    {
                        "label": "Standard Operating Procedure",
                        "value": "The assigned consultant will ensure End-Users are trained till they become adept."
                    },
                    {
                        "label": "Cybersecurity Maturity Program",
                        "value": "Adoption"
                    },
                    {
                        "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                        "value": "Adoption"
                    },
                    {
                        "label": "Breach Response Management - Readiness Assistance",
                        "value": "Adoption"
                    }
                ]







            }
        ]
    );

    const [filterData, setFilterData] = useState([])
    const fetchOptions = () => {
        try {
            const filteredData = options.filter((res) => res.id == id);
            setFilterData(filteredData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, [id]);


    return (
        <div>

            <div className='subscription_wrap'>
                <div className='subs_tile'>

                    <p className='text-center'>
                        Subscriptions are designed to provide Access-to-Expert-Cybersecurity-Practice
                    </p>
                </div>

                <div>
                    <ul>
                        <li><p>
                            Subscriptions are only to provide direct access to Expert Cybersecurity Consulting, Advisory, and best practices engaging certified cybersecurity professionals at any given time during the subscription term
                        </p></li>
                        <li><p> Subscription includes Services Delivery and Project Management Effort to achieve any specific objectives [ex, run a cyber-simulation, firewall deployment, application security testing, etc.] unless otherwise opted with a specific scope.
                        </p></li>
                        <li><p>
                            Services are designed to deliver an intended benefit and do not ever guarantee protection against breaches and compromise on Confidentiality, Integrity, and Availability. </p></li>
                        <li><p>
                            Services are designed to deliver an intended benefit and do not ever guarantee protection against breaches and compromise on Confidentiality, Integrity, and Availability. </p></li>
                        <li><p>
                            Throughout the term of the subscription, fnCyber will deploy a workforce to achieve the appropriate security objective; however, it's the responsibility of the customer organization to own all cybersecurity-related matters, missions, and consequences. </p></li>
                        <li><p>
                            Customer Organization is free to choose any other required particular/specific professional services that are to be procured from other product/service providers outside of fnCyber. The customer's decision is final in engaging any other third parties; fnCyber will only assist (if needed) in decision making. </p></li>
                    </ul>
                </div>

                <div className='subscription_wrap_table'>
                    <div class="custom_thead">
                        <div class="custom_row">
                            <div class="col-lg-1">
                                <p>
                                    S.No.
                                </p>

                            </div>
                            <div class="col-lg-4">
                                <p>
                                    Service Attribute
                                </p>

                            </div>

                        </div>
                    </div>
                    <div class="custom_tbody">
                        <div className="">
                            {filterData && filterData[0]?.Data.map((res, index) => (
                                <div key={index} className="custom_row">
                                    <div className="col-lg-1">
                                        <p>{index + 1}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <p className='color_cus'>{res?.label}</p>
                                    </div>
                                    <div>
                                        <p>{res?.value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>


                </div>
            </div ></div >
    )
}

export default Subscription