import React from "react";
import { useNavigate } from "react-router-dom";

function Success() {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const hanlde = () => {
    navigate("/");
  };
  return (
    <div>
      {" "}
      <div className="flex justify-center items-center">
        {queryParams && <h1>Payment Successfull</h1>}

      </div>
    </div>
  );
}

export default Success;
