import React from "react";
import styles from "../button/button.module.css";
import PropTypes from "prop-types";
import Download from "../../../Assets/images/download.png";
const Mainbtn = ({ buttonText, onClick, type, imageSrc, disabled }) => {

  return (
    <div className={styles.mainbutton}>
      <button
        className={styles.mainbtn}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {/* {imageSrc && (
          <img
            src={imageSrc}
            alt="Button Image"
            className={styles.buttonImage}
          />
        )} */}
        <span className={styles.mainbtndesc}>{buttonText}
         {/* {Download && (
          <img
            src={Download}
            alt="Button Image"
            className={styles.buttonImage}
          />
        )} */}
        </span>
      </button>
    </div>
  );
};

Mainbtn.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func, // You can specify the type for the onClick prop as well if needed
  type: PropTypes.oneOf(["button", "submit", "reset", undefined]), // Specify valid button types or allow it to be undefined
  imageSrc: PropTypes.string,
};

Mainbtn.defaultProps = {
  type: "button", // Set a default type if it's not provided
};

export default Mainbtn;
