import React from 'react'
import Profile from "../../../Components/Common/Profile/Profileinfo";

const Info = () => {
  return (
    <>
      <div className="profileview">
        <Profile />
      </div>

    </>
  )
}

export default Info
