import React from "react";
import logo from "../../../Assets/images/selectedservice.png";
import styles from "../servicescope/servicescope.module.css";

const index = (props) => {
  const { data } = props
  return (
    <><div className={styles.sltdes}>
      <div className="row">
        <div className="col-lg-8">
          <div className={styles.servicemain}>
            <div>
              <h3>Purpose</h3>
              <span dangerouslySetInnerHTML={{ __html: data?.definePurpose }}></span>

            </div>
            <div className={styles.detail}>
              <h3>Scope</h3>
              <span dangerouslySetInnerHTML={{ __html: data?.defineScope }}></span>

            </div>
          </div>
        </div>
        <div className="col-lg-4">

          <div className={styles.servscope}>
            <img src={data?.imageS3Url} alt="" />
          </div>

        </div>
        <div className="col-lg-6"></div>
        <div className="col-lg-6"></div>
      </div>
    </div>
    </>
  );
};

export default index;
