import React, { useContext, useEffect, useState } from "react";
import MainWrapper from "./MainWrapper";
import Sidenav from "./Sidenav";
import Topnav from "./Topnav";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../../Utils/Context";
import CommonModal from "../Common/CommonModal";
import AuthContext from "../../Utils/auth-context";
import { CARTGETAPI } from "../../Utils/services";
import { toast } from "react-toastify";

export default function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [cardItems, setCardItems] = useState([]);
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState({});
  const [toggle, setToggle] = useState(true);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isPageAddService =
    location.pathname.includes("addservice") ||
    location.pathname.includes("manageprojects");
  const containerClassName = `page-container ${isPageAddService ? "" : "bg_def"
    }`;
  const handleModalData = (data, size) => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // const handletoggle=()=>{
  //   if(toggle2){
  //     setToggle(!toggle)
  //   }

  // }
  const handletoggle = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    const isJSONString = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };
    // if (isJSONString(authCtx?.user)) {
    //   const data = JSON?.parse(authCtx?.user);
    //   if (data?.companyId === null) {
    //     navigate("/profile/profileinfo");
    //   }
    // } else {
    //   const data = authCtx?.user;
    //   if (data?.companyId === null) {
    //     navigate("/profile/profileinfo");
    //   }
    // }
  }, []);

  const company_id = JSON.parse(localStorage.getItem("user"))?.companyId;
  const fetchDatas = async () => {
    try {
      const result = await CARTGETAPI(company_id);
      if (result && result?.res.status === true) {
        setCardItems(result?.res?.data?.cartItemResponseList);
        const totalAmount = result?.res?.data.reduce(
          (total, item) => total + item.price,
          0
        );
      }
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  // useEffect(() => {
  //   fetchDatas();
  // }, [open]);

  return (
    <>
      <ModalContext.Provider
        value={{
          handleModalData,
          closeModal,
          isModalOpen,
          modalData,
          fetchDatas,
          cardItems,
          open

        }}
      >
        <div className="Layout">
          <div className="top-nav shadow-bottom">
            <Topnav fetchData={fetchDatas} cardItems={cardItems} open={open} setOpen={setOpen}/>
          </div>
          <div className="shadowbox"></div>
          <div className="Main-container flex">
            <div className="p-4 shadow_right">
              <Sidenav toggle={toggle} handletoggle={handletoggle} />
            </div>
            <div className={containerClassName}>
              <MainWrapper />
            </div>
          </div>
          
        </div>
      </ModalContext.Provider>
      <CommonModal
        handleModalData={handleModalData}
        isModalOpen={isModalOpen}
        modalData={modalData}
        modalSize={modalSize}
        closeModal={closeModal}
      />
    </>
  );
}
