import React from "react";
import { createButton } from "react-social-login-buttons";
import MicrosoftSVG from "../../../Assets/images/window.svg"; // Update the file path and extension if needed

// Define a custom icon component
const MicrosoftIcon = () => {
  return <img src={MicrosoftSVG} alt="Microsoft Icon" />;
};

// Wrap the custom icon component in a higher-order component
const MicrosoftIconComponent = () => {
  return <MicrosoftIcon />;
};

const config = {
  text: "Sign in with Microsoft",
  icon: MicrosoftIconComponent, // Use the custom icon component
  iconSize: "35px",
  style: {
    background: "#FFF",
    color: "#292728",
    padding: "30px 80px",
    border: "1px solid #707070",
    borderRadius: "10px",
  },
};

const Index = createButton(config);

export default Index;