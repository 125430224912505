import React from "react";

function SubTitle({ title, count }) {
  return (
    <div className="subtitle">
      {count ? (
        <h4>
          {title}
          <span className="count_brack">{count}</span>
        </h4>
      ) : (
        <h2 className="profilesub">{title}</h2>
      )}
    </div>
  );
}

export default SubTitle;
