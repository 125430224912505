import React, { useEffect, useState } from 'react'

function Subscription({ id }) {
    const [options, setoption] = useState(
        [
            {
                id: 1,
                label: 'Essentials',
                value: 'Essentials',
                Data: [
                    {
                        "label": "Service Type",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Collaboration via",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Response Time",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Security - Technical Assistance Level",
                        "collaboration": {
                            "accountable": "fnC",
                            "consult": "Customer",
                            "inform": "Customer"
                        }
                    },
                    {
                        "label": "Problem Elicitation",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Initiatives Driven by",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Issue Remedy",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Cybersecurity Best Practices & Controls",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Current State Assessment",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Solution Detailing",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Project Management Methodology",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "SecOps & IR Management Assistance Level",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Standard Operating Procedure",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Cybersecurity Maturity Program",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Breach Response Management - Readiness Assistance",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    }
                ]




            },
            {
                id: 2,
                label: 'Operational',
                value: 'Operational',
                Data: [
                    {
                        "label": "Service Type",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Collaboration via",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Response Time",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Security - Technical Assistance Level",
                        "collaboration": {
                            "accountable": "fnC",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Problem Elicitation",
                        "collaboration": {
                            "accountable": "fnC",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Initiatives Driven by",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Issue Remedy",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Cybersecurity Best Practices & Controls",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Current State Assessment",
                        "collaboration": {
                            "accountable": "fnC",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Solution Detailing",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Project Management Methodology",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "SecOps & IR Management Assistance Level",
                        "collaboration": {
                            "accountable": "fnC",
                            "responsible": ""
                        }
                    },
                    {
                        "label": "Standard Operating Procedure",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Cybersecurity Maturity Program",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    },
                    {
                        "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                        "collaboration": {
                            "accountable": "fnC",
                            "responsible": ""
                        }
                    },
                    {
                        "label": "Breach Response Management - Readiness Assistance",
                        "collaboration": {
                            "accountable": "Customer",
                            "responsible": "fnC"
                        }
                    }
                ]








            },
            {
                id: 3,
                label: 'Tactical',
                value: 'Tactical',
                Data:
                    [
                        {
                            "label": "Service Type",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Collaboration via",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Response Time",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Security - Technical Assistance Level",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer, fnC"
                            }
                        },
                        {
                            "label": "Problem Elicitation",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer, fnC"
                            }
                        },
                        {
                            "label": "Initiatives Driven by",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Issue Remedy",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Cybersecurity Best Practices & Controls",
                            "collaboration": {
                                "accountable": "Customer, fnC",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Current State Assessment",
                            "collaboration": {
                                "accountable": "Customer, fnC",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Solution Detailing",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer"
                            }
                        },
                        {
                            "label": "Project Management Methodology",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer, fnC"
                            }
                        },
                        {
                            "label": "SecOps & IR Management Assistance Level",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Standard Operating Procedure",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer"
                            }
                        },
                        {
                            "label": "Cybersecurity Maturity Program",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer, fnC"
                            }
                        },
                        {
                            "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                            "collaboration": {
                                "accountable": "Customer",
                                "responsible": "fnC"
                            }
                        },
                        {
                            "label": "Breach Response Management - Readiness Assistance",
                            "collaboration": {
                                "accountable": "fnC",
                                "responsible": "Customer"
                            }
                        }
                    ]



            },
            {
                id: 4,
                label: 'Strategic',
                value: 'Strategic',
                Data: [{
                    "label": "User",
                    "collaboration": {
                        "accountable": "Customer, fnC",
                        "responsible": "fnC"
                    }
                },
                {
                    "label": "Service Type",
                    "collaboration": {
                        "accountable": "Customer",
                        "responsible": "fnC"
                    }
                },
                {
                    "label": "Collaboration via",
                    "collaboration": {
                        "accountable": "Customer",
                        "responsible": "fnC"
                    }
                },
                {
                    "label": "Response Time",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer, fnC"
                    }
                },
                {
                    "label": "Security - Technical Assistance Level",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer, fnC"
                    }
                },
                {
                    "label": "Problem Elicitation",
                    "collaboration": {
                        "accountable": "Customer",
                        "responsible": "fnC"
                    }
                },
                {
                    "label": "Initiatives Driven by",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Issue Remedy",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Cybersecurity Best Practices & Controls",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Current State Assessment",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Solution Detailing",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Project Management Methodology",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "SecOps & IR Management Assistance Level",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Standard Operating Procedure",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Cybersecurity Maturity Program",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                },
                {
                    "label": "Breach Response Management - Readiness Assistance",
                    "collaboration": {
                        "accountable": "fnC",
                        "responsible": "Customer"
                    }
                }
                ]







            }
        ]
    );
    const [FullData, setFullData] = useState(
        [
            {
                "label": "Collaboration via Tickets",
                "value": "To ease the support and provide a trail of actions performed on a requirement, fnCyber leverages a CRM; and customers, when onboarded where applicable to a subscription, will have console access to raise/monitor/communicate on various requirements, work with a designated consultant in real-time and escalate as and when necessary; Emails are neither required nor necessary."
            },
            {
                "label": "Service Type",
                "value": "Elaborates who bears the effort in the service delivery process. Ex: Self-Service is entirely the customer effort"
            },
            {
                "label": "Current State Assessment",
                "value": "As-Is situation evaluation and analysis, performed via manual and automated tools, some of which rely on system-specific configurations needing administrative and system-level authorizations"
            },
            {
                "label": "Response Time",
                "value": "Time taken to react (only) to a query/request/requirement or an issue raised and does not mean the completion of the same (neither implicitly nor explicitly)"
            },
            {
                "label": "Problem Elicitation",
                "value": "A demystifying process to uncover and interpret the underlying issues, directly and/or indirectly, causing the problem; the relevancy of which largely depends on the factual representation of information provided for the very purpose"
            },
            {
                "label": "Initiatives Driven by",
                "value": "Initiatives are Tasks/Objectives/Projects to be accomplished and owned by a stakeholder to oversee the same till completion."
            },
            {
                "label": "Issue Remedy",
                "value": "The Issue is a Pain-Point, and Remedy is a solution to relieve it and describes the level to which the problem is addressed"
            },
            {
                "label": "Solution Detailing",
                "value": "The level of detail, information, and expert guidance provided in the recommended solution."
            },
            {
                "label": "Project Management Methodology (Add-Ons)",
                "value": "An approach defining - \"How to Accomplish an Objective\"; subscription plan draws a respective Project Management Plan for the problem at hand along with the detailed solution. Add-Ons refer to the additional effort required to execute the above-derived project plan and needed to be procured in addition to the subscription. Both Subscription and Add-on services are designed to complement each other as modularly as possible. Add-ons are specific services along with respective governing service terms. The customer reserves the right to choose the Add-Ons and also has full rights to execute the project by any other means."
            },
            {
                "label": "Security - Technical Assistance Level",
                "value": "The cybersecurity knowledge, conceptual, logical, and best practices; procedural relevancy and/or deficiency; compliance to the process; deviations and/or gaps - none (at all) of which are related to the product architecture and information"
            },
            {
                "label": "SecOps & IR Management Assistance Level",
                "value": "Security Operations activities and guidance pertaining to Detection, Prevention, Identification, Response and Recovery, Post-Mortem [based on NIST Cybersecurity Framework]; playbook audits and relevance, improvements in the Incident Detection and Response capability; optimizing monitoring environment; assist in expanding monitoring capabilities to other assets and environments; end-of-life recommendations, consolidating to improve return on Security Investments - none (at all) of which are related to the product architecture and information"
            },
            {
                "label": "Standard Operating Procedure",
                "value": "Security Technology/Platform documentation, Step-by-Step Procedural Expert Guidance to accomplish a task/activity/objective"
            },
            {
                "label": "Cybersecurity Best Practices & Controls",
                "value": "Guidance on Industry best practices from NIST, CISA, CIS, MITRE, ITIL, PMP"
            },
            {
                "label": "Cybersecurity Maturity Program",
                "value": "Guidance on Industry relevant Cybersecurity Maturity Program: Ex: CMMC 2.0 of US DoD"
            },
            {
                "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                "value": "Guidance on Governance, Risk and Compliance based on COBIT5 & CONSENSUS ASSESSMENTS INITIATIVE QUESTIONNAIRE v3.1"
            },
            {
                "label": "Breach Response Management - Readiness Assistance",
                "value": "Assist in Preparing a Readiness and Contingency Plan based on Customers' BC and DR for Information and Technology Attacks and Breach; assist with simulations in assessing response effectiveness."
            }
        ]

    )

    const [filterData, setFilterData] = useState([])
    const fetchOptions = () => {

        try {
            const filteredData = options.filter((res) => res.id == id);
            setFilterData(filteredData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, [id]);


    return (
        <div>

            <div className='subscription_wrap'>
                <div className='subs_tile'>

                    <p className='text-center'>
                        Subscriptions are designed to provide Access-to-Expert-Consulting-Advisory
                    </p>
                </div>

                <div>
                    <ul>
                        <li><p>
                            Subscriptions are only to provide direct access to Expert Cybersecurity Consulting, Advisory, and best practices engaging certified cybersecurity professionals at any given time during the subscription term


                        </p></li>
                        <li><p> Subscription includes Services Delivery and Project Management Effort to achieve any specific objectives [ex, run a cyber-simulation, firewall deployment, application security testing, etc.] unless otherwise opted with a specific scope.


                        </p></li>
                        <li><p>
                            Services are designed to deliver an intended benefit and do not ever guarantee protection against breaches and compromise on Confidentiality, Integrity, and Availability.
                        </p></li>
                        <li><p>
                            Throughout the term of the subscription, fnCyber will deploy a workforce to achieve the appropriate security objective; however, it's the responsibility of the customer organization to own all cybersecurity-related matters, missions, and consequences.

                            . </p></li>
                        <li><p>
                            Customer Organization is free to choose any other required particular/specific professional services that are to be procured from other product/service providers outside of fnCyber. The customer's decision is final in engaging any other third parties; fnCyber will only assist (if needed) in decision making..</p></li>

                    </ul>
                </div>

                <div className='subscription_wrap_table'>
                    <div class="custom_thead">
                        <div class="custom_row">
                            <div class="col-lg-1">
                                <p>
                                    S.No.
                                </p>

                            </div>
                            <div class="col-lg-4">
                                <p>
                                    Service Attribute
                                </p>

                            </div>

                        </div>
                    </div>
                    <div class="custom_tbody">
                        <div className="">
                            {filterData && filterData[0]?.Data.map((res, index) => (
                                <div key={index} className="custom_row">
                                    <div className="col-lg-1">
                                        <p>{index + 1}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <p className='color_cus'>{res?.label}</p>
                                    </div>
                                    <div>

                                        <div>
                                            <p>{res?.collaboration?.accountable}</p>
                                        </div>
                                        <div>
                                            <p>{res?.collaboration?.responsible}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>


                </div>
                <div className='subs_tile'>

                    <p className='text-center'>
                        Meanings of Terms used in Consulting Attributes
                    </p>
                </div>
                <div className='subscription_wrap_table'>
                    <div class="custom_thead">

                    </div>
                    <div class="custom_tbody">
                        <div className="">
                            {FullData && FullData.map((res, index) => (
                                <div key={index} className="custom_row">
                                    <div className="col-lg-1">
                                        <p>{index + 1}</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <p className='color_cus'>{res?.label}</p>
                                    </div>
                                    <div>

                                        <div>
                                            <p>{res?.value}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>


                </div>
            </div ></div >
    )
}

export default Subscription