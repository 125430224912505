import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import FormControl from '../../Components/Common/controls/formcontrol';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATESUBSCRPTIONAPI, GetCustomerApi } from '../../Utils/services';
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import React, { useContext, useState,useEffect } from "react";
import AuthContext from '../../Utils/auth-context';
function BillingForm() {
    const authCtx = useContext(AuthContext);
    const [allData, setAllData] = useState({});
    const [loading, setloading] = useState(false);
    const location = useLocation();
  
    const navigate = useNavigate();
    const yourData = location.state?.yourData;
     console.log(yourData?.serviceIdselected,"yourData")
     console.log(yourData,"yourData")



     const fetchData = async () => {
        const isJSONString = (str) => {
          try {
            JSON.parse(str);
            return true;
          } catch (e) {
            return false;
          }
        };
    
        if (isJSONString(authCtx?.user)) {
          const data = JSON?.parse(authCtx?.user);
    
          try {
            setloading(true);
            const result = await GetCustomerApi(data?.companyId);
            if (result && result?.res.status === true) {
                console.log(result?.res?.data?.billingDetails,"result?.res?.data?.billingDetails")
                console.log(result?.res?.data?.billingDetails?.length,"result?.res?.data?.billingDetails")
                if(result?.res?.data?.billingDetails.length > 0){
                    const mergedData = { ...data, ...result?.res?.data && result?.res?.data?.billingDetails};
                    setAllData(mergedData);
                    console.log(mergedData,"mergedData")
                    setloading(false);
                }else{
                    navigate('/profile',localStorage.setItem('activeTab', 3));
                }
            
            } else {
              setloading(false);
            }
          } catch (error) {
            setloading(false);
            // Fallback to test data
          }
        } else {
          const data = authCtx?.user;
    
          try {
            setloading(true);
            const result = await GetCustomerApi(data?.companyId);
            if (result && result?.res.status === true) {
              const mergedData = { ...data, ...result?.res?.data && result?.res?.data?.billingDetails };
              setAllData(mergedData);
              console.log(mergedData,"mergedData")
              setloading(false);
            } else {
              setloading(false);
            }
          } catch (error) {
            setloading(false);
            // Fallback to test data
          }
        }
      };
    
      useEffect(() => {
        fetchData();
      }, [authCtx?.user]);





    const handleSubmit = async (values) => { 
        const data = {
            ...values
        };
        try {
            const result = await CREATESUBSCRPTIONAPI({
                "billingId": allData[0]?.billingId  && allData[0]?.billingId || "",
                "customerId": yourData?.customerId  && yourData?.customerId ,
                "planId":  yourData?.serviceIdselected === 1
                ? "plan_Opr5SgV3CkDTnv"
                : yourData?.serviceIdselected === 4
                ? "plan_NKQJKeZ7SKCsbB"
                : yourData?.serviceIdselected === 2
                ? "plan_Opr69dU46btfGX"
                : yourData?.planId ,
                "companyId": yourData?.companyId && yourData?.companyId ,
                "serviceId": yourData?.serviceId && yourData?.serviceId ,
                "totalCount": yourData?.totalCount && yourData?.totalCount,
                "mainId":
                yourData?.serviceIdselected === 1
                  ? "1656200d-d0d9-4f05-95ce-6d28dad73010"
                  : yourData?.serviceIdselected === 4
                  ? "e67dffc2-3d31-4709-9767-d2fc9dfab497"
                  : yourData?.serviceIdselected === 2
                  ? "d60ecd51-2244-42ae-8ede-be2f1b07f09e"
                  : ""
            });

            if (result.res.status === true) {
                toast.success("Redirecting to the payment screen. If your purchase is successful, it will be reflected in the dashboard in a few minutes.");
                window.open(result.res.data);
                // navigate('https://user.fncyber.techtonic.asia/');
            }
        } catch (err) {
            // Handle error
        }
    };

    const validationSchema = Yup.object().shape({
        billingDetails: Yup.object().shape({
            fullName: Yup.string().required('Full Name is required'),
            address1: Yup.string().required('Address Line 1 is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zipCode: Yup.string().required('Zip Code is required'),
            country: Yup.string().required('Country is required'),
            billingemail: Yup.string().email('Invalid email address').required('Email Address is required'),
            phoneNumber: Yup.string()
                .required('Phone Number is required')
                .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Phone number must be a valid international phone number')
        }),
    });
    console.log(allData,"allData")
    return (
        <div className="overview _h_100 d-flex justify-content-center align-items-center">
             {allData && allData[0]?.fullName && (
            <Formik
                initialValues={{
                    billingDetails: {
                        fullName: allData[0]?.fullName && allData[0]?.fullName   || "" ,
                        address1: allData[0]?.address1 && allData[0]?.address1 || "" ,
                        address2: allData[0]?.address2 && allData[0]?.address2 || "",
                        city: allData[0]?.city && allData[0]?.city || "",
                        state: allData[0]?.state && allData[0]?.state || "",
                        zipCode: allData[0]?.zipCode  && allData[0]?.zipCode|| "",
                        country: allData[0]?.country && allData[0]?.country || "",
                        billinggst: allData[0]?.gstNumber && allData[0]?.gstNumber || "",
                        billingemail: allData[0]?.billingEmail && allData[0]?.billingEmail || "",
                        phoneNumber: allData[0]?.phoneNumber  && allData[0]?.phoneNumber || "",
                        
                        // billingId: allData[0]?.billingId  && allData[0]?.billingId || "",
                        // customerId: yourData?.customerId  && yourData?.customerId ,
                        // planId: yourData?.planId && yourData?.planId ,
                        // companyId: yourData?.companyId && yourData?.companyId ,
                        // serviceId: yourData?.serviceId && yourData?.serviceId ,
                        // serviceId: yourData?.totalCount && yourData?.totalCount ,
                        // mainId:
                        // yourData?.serviceIdselected === 1
                        //   ? "1656200d-d0d9-4f05-95ce-6d28dad73010"
                        //   : yourData?.serviceIdselected === 4
                        //   ? "e67dffc2-3d31-4709-9767-d2fc9dfab497"
                        //   : yourData?.serviceIdselected === 2
                        //   ? "d60ecd51-2244-42ae-8ede-be2f1b07f09e"
                        //   : ""

                    },
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form className='col-lg-10'>
                        <div className='plan_wrapper form_profile'>
                            <div className='plan_head'>
                                <h1>Billing Details</h1>
                            </div>
                            <div className='row gy-3'>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        name="billingDetails.fullName"
                                        placeholder="Full Name"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Address Line 1"
                                        name="billingDetails.address1"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Address Line 2"
                                        name="billingDetails.address2"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="City"
                                        name="billingDetails.city"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="State"
                                        name="billingDetails.state"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Zip"
                                        name="billingDetails.zipCode"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Country"
                                        name="billingDetails.country"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Email Address"
                                        name="billingDetails.billingemail"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <PhoneInput
                                    
                                        defaultCountry="IN"
                                        countryCodeEditable={true}
                                        // name="billingDetails.phoneNumber"
                                        // placeholder="Phone Number"
                                        // value={formik.values.billingDetails.phoneNumber}
                                        onChange={(value) => formik.setFieldValue('billingDetails.phoneNumber', value)}
                                    />
                                    {formik.errors.billingDetails?.phoneNumber && formik.touched.billingDetails?.phoneNumber ? (
                                        <div className="error">{formik.errors.billingDetails.phoneNumber}</div>
                                    ) : null}
                                </div>
                                <div className='col-lg-6'>
                                    <FormControl
                                        control="Input"
                                        placeholder="Gst No."
                                        name="billingDetails.billinggst"
                                    />
                                </div>
                                 <div className='d-flex justify-content-end'>
                                    <div className='px-4 py-2 Monthly active'>
                                        <button type='submit'>Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
                )}
        </div>
    );
}

export default BillingForm;
