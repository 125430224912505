import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { Elements } from "@stripe/react-stripe-js";

function index() {
  const stripePromise = loadStripe(
    "pk_test_51HSiLbET2Bf43bMhNvLwm181yCS168wke2StQE6kvOZxD8A7Cjn3M7OiFatYPcbcMq9UsjKDLosqMF912dlttY3000dsU3f14G"
  );
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
}

export default index;
