import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import FormControl from "../../controls/formcontrol";
import Submitbtn from "../../button";
import styles from "./profileinfo.module.css";
import Loader from "../../Loader";
import { CustomerCreate, GetCustomerApi, UPDATEPROFILEAPI, GETPROFILEAPI, CUSTOMERUPDATEAPI } from "../../../../Utils/services";
import * as Yup from "yup";
import AuthContext from "../../../../Utils/auth-context";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { FaCircle, FaStore } from 'react-icons/fa';
import addprofile from "../../../../Assets/images/addprofile.png";

const CompanyInfo = () => {
    const authCtx = useContext(AuthContext);
    const [userData, SetUserData] = useState();
    const [loading, setloading] = useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [CompanyData, SetCompanyData] = useState({});

    const fetchData = async () => {
        setloading(true);
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx?.user)) {
            const data = JSON?.parse(authCtx?.user);
            const parsedUserData = JSON.parse(authCtx.user);

            try {
                const result = await GetCustomerApi(data?.companyId);
                if (result && result?.res.status === true) {
                    if (isJSONString(authCtx.user)) {
                        // Merge allData and parsedUserData
                        const mergedData = { ...result?.res?.data, ...parsedUserData };
                        SetUserData(mergedData);
                        setloading(false);
                    } else {
                        SetUserData(parsedUserData);
                        setloading(false);
                    }
                } else {
                    SetUserData(parsedUserData);
                    setloading(false);
                }
            } catch (error) {
                setloading(false);
                SetUserData(parsedUserData);
            }
        } else {
            const data = authCtx?.user;
            const parsedUserData = authCtx.user;
            try {
                const result = await GetCustomerApi(data?.companyId);
                if (result && result?.res.status === true) {
                    // Merge allData and parsedUserData
                    const mergedData = { ...result?.res?.data, ...parsedUserData };
                    SetUserData(mergedData);
                    setloading(false);
                } else {
                    SetUserData(parsedUserData);
                    setloading(false);
                }
            } catch (error) {
                setloading(false);
                SetUserData(parsedUserData);
                // Fallback to test data
            }
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handle = async (values) => {

        if (values.formData?.companyId) {
            // debugger
            try {
                setloading(true);
                const result = await CUSTOMERUPDATEAPI(values.formData);

                if (result?.res?.status == true) {
                    const newObj = {
                        ...authCtx?.user,
                        companyId: result?.res?.data?.companyId,
                    };
                    localStorage.setItem("user", JSON.stringify(newObj));
                    setloading(false);
                    toast.success(result?.res?.message || "Company Updated successfully");
                    fetchData();
                }
                else {
                    setloading(false);
                    toast.error(result?.res?.message)
                }
            } catch (error) {
                toast.error(error || error.message);
                setloading(false);
            }
        } else {
            try {
                setloading(true);
                const result = await CustomerCreate(values.formData);

                if (result?.res?.status == true) {
                    const newObj = {
                        ...authCtx?.user,
                        companyId: result?.res?.data?.companyId,
                    };
                    localStorage.setItem("user", JSON.stringify(newObj));
                    authCtx?.login(newObj);
                    setloading(false);
                    toast.success(result?.res?.message || "Company created successfully");
                }
                else {
                    setloading(false);
                    toast.error(result?.message)
                }
            } catch (error) {
                toast.error(error || error.message);
                setloading(false);
            }
        }
    };

    const schema = Yup.object().shape({
        formData: Yup.object().shape({
            organisationName: Yup.string().required("Name of Organisation is required"),
            industry: Yup.string().required("Industry is required"),
            companySize: Yup.number().required("Company size is required"),
            location: Yup.string().required("Location is required"),
            typeofSensitiveData: Yup.string().required("Type of Sensitive Data is required"),
            securityInformation: Yup.object().shape({
                cyberSecurityChallenge: Yup.string().required("Cybersecurity Challenge is required"),
                securityMeasure: Yup.string().required("Security Measure is required"),
                complianceRequirement: Yup.string().required("Compliance Requirement is required"),
                pastExperiences: Yup.string().required("Past Experiences is required"),
            }),
        }),
    });


    return (
        <>
            <Loader loading={loading} />
            <div>
                {/* <h2 className={styles.profhead}>Profile</h2> */}
                {/* <span className={styles.prodetail}>
          Please provide the following details, it will help us serve you better
        </span> */}
            </div>
            {userData && (
                <Formik
                    initialValues={{
                        formData: {
                            companyId: userData?.companyId || "",
                            name: userData?.name || "",
                            userEmail: userData?.email || "",
                            primaryRole: userData?.primaryRole || "",
                            fromAdmin: false,
                            organisationName: userData?.organisationName,
                            industry: userData?.industry,
                            companySize: userData?.companySize,
                            location: userData?.location,
                            typeofSensitiveData: userData?.typeofSensitiveData,
                            securityInformation: {
                                cyberSecurityChallenge:
                                    userData?.securityInformation?.cyberSecurityChallenge || "",
                                securityMeasure:
                                    userData?.securityInformation?.securityMeasure || "",
                                complianceRequirement:
                                    userData?.securityInformation?.complianceRequirement || "",
                                pastExperiences:
                                    userData?.securityInformation?.pastExperiences || "",
                            },
                        },
                    }}
                    onSubmit={handle}
                    validationSchema={schema}
                >
                    {(formik) => (
                        <Form className={styles.form_profile}>
                            <div className="row">
                                <div className={styles.proorganisat}>
                                    <div>
                                        <h3 className={styles.profilesubhead}>
                                            About Organisation
                                        </h3>
                                    </div>

                                    <div className={styles.proedit}>
                                        <FormControl
                                            control="Input"
                                            placeholder="Name of Organisation"
                                            name="formData.organisationName"

                                        // value={Formik.values.formData?.organisationName}
                                        />
                                    </div>
                                    <div className={styles.profileorgani}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <FormControl
                                                    control="Input"
                                                    placeholder="industry"
                                                    name="formData.industry"
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <FormControl
                                                    type="number"
                                                    control="Input"
                                                    placeholder="Company size"
                                                    name="formData.companySize"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.proedit}>
                                        <FormControl
                                            control="Input"
                                            placeholder="Location"
                                            type="text"
                                            name="formData.location"
                                        />
                                    </div>
                                    <div className={styles.proedit}>
                                        <span className={styles.prodetail}>
                                            What type of sensitive data does your organisation
                                            handle?
                                        </span>
                                        <FormControl
                                            control="Input"
                                            type="text"
                                            placeholder="(e.g.,customer information,financial data, intellectual property)"
                                            name="formData.typeofSensitiveData"
                                        />
                                    </div>



                                </div>
                                <div className="col-lg-12">
                                    <div className={styles.profilequest}>
                                        <div>
                                            <h3 className={styles.profilesubhead}>
                                                About Security
                                            </h3>
                                        </div>

                                        <div className={styles.securitydesc}>
                                            <span className={styles.prodetail}>
                                                What are your primary cybersecurity concerns or
                                                challenges?
                                            </span>
                                            <FormControl
                                                control="Textarea"
                                                placeholder=""
                                                rows="2"
                                                maxRows="4"
                                                name="formData.securityInformation.cyberSecurityChallenge"
                                            />
                                        </div>
                                        <div className={styles.securitydesc}>
                                            <span className={styles.prodetail}>
                                                What are your current security measures?
                                            </span>
                                            <FormControl
                                                control="Textarea"
                                                placeholder=""
                                                rows="2"
                                                maxRows="4"
                                                name="formData.securityInformation.securityMeasure"
                                            />
                                        </div>
                                        <div className={styles.securitydesc}>
                                            <span className={styles.prodetail}>
                                                Past experience in cybersecurity incidents or
                                                breaches?
                                            </span>
                                            <FormControl
                                                control="Textarea"
                                                placeholder=""
                                                rows="2"
                                                maxRows="4"
                                                name="formData.securityInformation.complianceRequirement"
                                            />
                                        </div>
                                        <div className={styles.securitydesc}>
                                            <span className={styles.prodetail}>
                                                Do you need to be compliant with any specific
                                                cybersecurity standards?
                                            </span>
                                            <FormControl
                                                control="Textarea"
                                                placeholder=""
                                                rows="2"
                                                maxRows="4"
                                                name="formData.securityInformation.pastExperiences"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.cstdetail}>
                                <Submitbtn buttonText="Done" type="submit" />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default CompanyInfo;
