import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./writereview.module.css";
import { Formik, Form } from "formik";
import FormControl from "../../Components/Common/controls/formcontrol";
import Star from "../../Components/Common/ratings";
import Submitbtn from "../../Components/Common/button";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Staremp from "../../Components/Common/empratings";
import { GETREVIEWAPI, CREATEREVIEWAPI } from "../../Utils/services";
import { toast } from "react-toastify";

function Review() {
  const { id } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const fetchData = async () => {
    try {
      const result = await GETREVIEWAPI(id);
      if (result?.res) {
        setReviewData(result?.res.data);
        setEmployeeData(result?.res?.data?.employeeReviewDtoList);
      }
    } catch { }
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  const handleRating = (data, value) => {
    setEmployeeData((prev) => {
      const updatedData = prev.map((item) => {
        if (item?.employeeID == data) {
          return {
            ...item,
            employeeRating: value,
            employeeId: data
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handle = async (values) => {

    if (!reviewData?.reviewId) {
      const data = {
        employeeRatings: employeeData,
        purchaseTaskId: id,
        title: values.formData.title,
        reviewText: values.formData.reviewText,
        taskRating: values.formData.taskRating,
      };

      if (data) {

        try {
          const result = await CREATEREVIEWAPI(data);

          if (result?.res?.status) {
            toast.success(result?.res?.message || "Company Updated successfully");
            toast.warn("User can review only one time");
            fetchData()

          }
        } catch (error) {
          toast.error(error);
        }
      }
    }
    else {
      toast.warn("User can review only one time")
    }

  };
  const isDisabled = reviewData?.reviewId ? true : false;
  const validationSchema = Yup.object().shape({
    formData: Yup.object().shape({

      title: Yup.string().required("Title is required"),
      reviewText: Yup.string().required("Review text is required"),

    }),
  });

  return (
    <div className="overeview">
      {" "}
      <>
        <div className={styles.review}>
          <h2 className={styles.reviehead}>Leave a review</h2>
          <span className={styles.revifeed}>Your feedback matters to us!</span>
        </div>
        {/* <Loader Loading={loading} /> */}
        {reviewData?.serviceName && (
          <Formik
            initialValues={{
              formData: {
                taskRating: reviewData?.taskRating || 0,
                title: reviewData?.title || "",
                reviewText: reviewData?.reviewText || "",
                employeeReviewDtoList: reviewData?.employeeReviewDtoList || [],
              },
            }}
            onSubmit={handle}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form className={styles.form_review}>
                <div className="row">
                  <div className="col-lg-6">
                    <div>
                      <div>
                        <h3 className={styles.reviewques}>
                          How would you rate the service?
                        </h3>
                        <div className={styles.reviestar}>
                          <FormControl
                            control="Rating"
                            name="formData.taskRating"
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div>
                        <div className={styles.div_review}>
                          <div className={styles.reviewdesc}>
                            <h3 className={styles.reviewques}>
                              Title your review
                            </h3>
                            <FormControl
                              control="Input"
                              name="formData.title"
                              disabled={isDisabled}
                            />
                          </div>
                          <div>
                            <h3 className={styles.reviewques}>
                              Write your review
                            </h3>
                            <FormControl
                              control="Textarea"
                              cols="40"
                              rows="6"
                              name="formData.reviewText"
                              disabled={isDisabled}
                            />
                            <span>This will be visible to public.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={styles.reviewtable}>
                      <div>
                        <h3 className={styles.reviewques}>
                          How would you rate our employees?
                        </h3>
                      </div>
                      {employeeData &&
                        employeeData?.map((review) => (
                          <div key={review.id} className={styles.emplreview}>
                            <div>
                              <h4 className={styles.reviewques}>
                                {review?.employeeName}
                              </h4>
                              <span>{review?.roleName}</span>
                            </div>
                            <div>
                              <Staremp
                                name={review?.employeeID}
                                count={review?.employeeRating}
                                handle={handleRating}
                                disabled={isDisabled}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.reviewsbt}>
                  <Submitbtn
                    buttonText="Done"
                    type="submit"
                    disabled={isDisabled}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    </div>
  );
}

export default Review;
