import React, { useContext } from "react";
import Ongoingservice from "../../Components/Common/Ongoingservice";
import Browserservice from "../../Components/Common/BrowserService";
import { useEffect, useState } from "react";
import { GETOWNSERVICEApi } from "../../Utils/services";
import AuthContext from "../../Utils/auth-context";
import Loader from "../../Components/Common/Loader";

const Homeongonig = () => {
  const [onGoing, setonGoing] = useState([]);
  const [allData, setAllData] = useState({});
  const [loading, setloading] = useState(false);
  const data = localStorage?.getItem("user");
  const authCtx = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);

  const fetchData = async () => {
    try {
      setloading(true)
      const result = await GETOWNSERVICEApi(JSON.parse(data)?.companyId);
      if (result?.res?.status === true) {
        const category_1 = result?.res?.data?.filter(
          (res) => res?.projectType === 2 || res?.projectType === 1
        );
        setonGoing(category_1);
        setloading(false)
      }
    } catch (err) {
      setloading(false)
    }
  };

  const Payment = () => {

  };
  useEffect(() => {
    fetchData();
  }, [JSON.parse(data)?.companyId]);
  useEffect(() => {
    Payment();
  }, [queryParams]);
  const isJSONString = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  const fetchDatALl = () => {
    if (isJSONString(authCtx.user)) {
      const parsedUserData = JSON.parse(authCtx.user);
      setAllData(parsedUserData);
    } else {
      setAllData(authCtx.user);
    }
  };

  useEffect(() => {
    fetchDatALl();
  }, [authCtx.user]);


  return (
    <div className="overview">
      <div>
        <h2 className="overviewhead">Hello, {allData?.name}</h2>
      </div>
      <Loader loading={loading} />
      {onGoing.length > 0 && <Ongoingservice data={onGoing} /> }
      <Browserservice />
    </div>
  );
};

export default Homeongonig;
