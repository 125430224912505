import React, { useMemo } from "react";
import FormControl from "../../controls/formcontrol";
import Download from "../../../../Assets/images/download.png";
import DefaultTable from "../../table";
import SubTitle from "../../controls/Heading/SubTitle";
import { filesize } from "filesize";
import { saveAs } from "file-saver";

function DownloadFile(props) {
  const { closeModal, data } = props;

  console.log(data, "data")
  const handle = async (link, name) => {
    try {
      saveAs(link, name);
    } catch (error) {


    }
  };

  const FeedsColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "documentName",
      },

      ,
      {
        Header: "Size",
        accessor: (data) => {
          return (
            <>
              <div className="acess_icon">
                <p>{((data?.documentSize))}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Download",
        accessor: (data) => {
          return (
            <>
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={() => handle(data?.documentS3Url, data.documentName)}
              >
                <div className="icon_image">
                  <img src={Download} />
                </div>
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      {" "}
      <div className="row">
        <div className="com_modal">
          <div className="mb-4 text-center">
            <FormControl control="heading" title="Download Files" />
          </div>
          <div className="mb-2 sub_title">
            <SubTitle title={data?.serviceName} />
          </div>
          <div className="project_table">
            {data && (
              <DefaultTable data={data?.documentList ? data?.documentList : data.documentFile} columns={FeedsColumns} />
            )}
          </div>

          <div className="d-flex justify-content-evenly align-items-center my-3">
            {/* <div className="yes_button" onClick={() => handle()}>
              DownLoad
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadFile;
