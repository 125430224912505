import React, { useState, useEffect, useContext } from 'react';
import { CustomTabs, CustomTabPanel } from '../../Components/Common/Tabs';
import Myprofile from '../Myprofile';
import Profileinfo from '../../Components/Common/Profile/Profileinfo';
import CompanyInfo from '../../Components/Common/Profile/CompanyInfo';
import UserTable from '../../Components/Common/Profile/UserTable';
import AuthContext from '../../Utils/auth-context';
// import Billing2 from "../../Pages/BillingForm";
import Billing2 from "../../Pages/BillingForm";
import AddBillingDetail from '../AddBillingDetail';
function ProfileWrap() {
    const authCtx = useContext(AuthContext);
    const [admin, setAdmin] = useState(false);
    const [initialTab, setInitialTab] = useState(3);

    useEffect(() => {
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };

        if (isJSONString(authCtx?.user)) {
            const data = JSON.parse(authCtx?.user);
            if (data?.primaryRole === 'ADMIN') {
                setAdmin(true);
            }
        } else {
            const data = authCtx?.user;
            if (data?.primaryRole === 'ADMIN') {
                setAdmin(true);
            }
        }

        // Set the initial tab once when the component mounts
        const savedTab = parseInt(localStorage.getItem('activeTab')) ;
        setInitialTab(savedTab);
    }, [authCtx?.user]);

    

    // State to manage the active tab
    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTab', activeTab?.toString());
    }, [activeTab]);

    return (
        <div>
            <div className="overview">
                <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab}>
                    <CustomTabPanel tabLabel="User Profile" key="1">
                        <Profileinfo />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Company Profile" key="2">
                        <CompanyInfo />
                    </CustomTabPanel>
                    {admin && (
                        <CustomTabPanel tabLabel="All Users" key="3">
                            <UserTable />
                        </CustomTabPanel>
                    )}
                    {admin && (
                        <CustomTabPanel tabLabel="Billing" key="4">
                            <AddBillingDetail />
                        </CustomTabPanel>
                    )}
                </CustomTabs>
            </div>
        </div>
    );
}

export default ProfileWrap;
