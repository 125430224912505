import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Button } from "@material-ui/core";
import { SidebarRegister } from "../../../Components/Common/Cart";
import Btn from "../../../Components/Common/submitbtn";
import logo from "../../../Assets/images/Colorcart.svg";
import Downloadimg from "../../../Assets/images/download.png";
import styles from "../Addtocart/Addtocart.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { CARTADDAPI, CARTGETAPI, CARTDELTEAPI } from "../../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../../Utils/Context";
import DownloadFile from "../Modals/DownloadFile";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {},
  hide: {
    display: "none",
  },

  drawerPaper: {
    backgroundColor: "#EDD39F",
    padding: "30px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

export default function Addtocart({ data ,state}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [cardItems, setCardItems] = useState([]);
  const { id } = useParams();
  const company_id = JSON.parse(localStorage.getItem("user"))?.companyId;
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData, fetchDatas} = modalContext;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fetchData = async () => {
    try {
      const result = await CARTGETAPI(company_id);
      if (result && result?.res.status === true) {
        setCardItems(result?.res?.data?.cartItemResponseList);
        if(result?.res?.data?.cartItemResponseList?.length === 0){
          setOpen(false);
        }
        const totalAmount = result?.res?.data.reduce(
          (total, item) => total + item.price,
          0
        );
      }
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(state,"statecart")
  const navigate = useNavigate();
  const handleCart = async () => {  

    // console.log
    if(state?.title === "Essential Service" || state?.title === "Operational Services" || state?.title === "Tactical Services"){
      // console.log("data","/services/allservices")
      navigate(`/Purchaseplan`,{state: state });
    }else{
      if (company_id != null) {
        const submitData = {
          companyId: company_id,
          taskId: data?.serviceId,
          price: data?.price,
        };
        try {
          const result = await CARTADDAPI(submitData);
  
          if (result && result?.res.status === true) {
            toast.success(result?.res.message);
            fetchData();
            fetchDatas();
          }
        } catch (error) {
          toast.error(error || error.message);
        }
      } else {
        toast.error("Make Compnay First");
      }
    };
    }
  
 

  const handleDelete = async (id) => {
    try {
      const result = await CARTDELTEAPI(id);
      if (result.res.status == true) {
        toast.error(result.res.message);
        fetchData();
        fetchDatas();
      }
    } catch (error) {
      toast.error(error || error.message);
    }
  };
  const handledownload = () => {

    const Download = <DownloadFile closeModal={closeModal} data={data} />;
    handleModalData(Download, "md");
  };
  return (
    <div className={styles.addcart}>
      <div className="col-lg-9">
        <div className={styles.sltddetail}>
          <h2>{data?.serviceName}</h2>
          <span dangerouslySetInnerHTML={{ __html: data?.shortDescription }} className="shortDescription"></span>

          <div >
            {/* <Btn buttonText="Download Files" onClick={handledownload} /> */}
            <Btn buttonText="Download Files" onClick={handledownload} />
            {/* <div><img src={Downloadimg} alt="" /></div> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className={styles.addbtn}>
          <div className={styles.sltcart}>
            <div className={styles.sltimag} onClick={() => handleCart()}>
              <img src={logo} alt="" onClick={handleDrawerOpen} />
            </div>
            <div onClick={() => handleCart()}>
              <Button onClick={handleDrawerOpen}>
                <span className={styles.sltext}>Add to cart</span>
              </Button>
            </div>
          </div>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <SidebarRegister
              handleDrawerClose={handleDrawerClose}
              data={cardItems}
              handleDelete={handleDelete}
            />
          </Drawer>
        </div>
      </div>
    </div>
  );
}
