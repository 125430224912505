import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SidebarRegister } from "../../Components/Common/Cart";
import Btn from "../../Components/Common/button";
import Sidenav from "./Sidenav";
import { Drawer, Button } from "@material-ui/core";
import Cross from "../../Assets/images/Cross_icon.png";
import Ham from "../../Assets/images/hamburger_menu.png";
import logo from "../../Assets/images/logo_new.png";
import { Link } from "react-router-dom";
import search from "../../Assets/images/search.png";
import cart from "../../Assets/images/cart.png";
import styles from "../../Components/Layout/topnav.module.css";
import { toast } from "react-toastify";
import { CARTADDAPI, CARTDELTEAPI, CARTGETAPI } from "../../Utils/services";

// const drawerWidth = 370;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  hide: {
    display: "none",
  },
  // drawer: {
  //   width: drawerWidth,
  // },
  drawerPaper: {
    // width: drawerWidth,
    backgroundColor: "#EDD39F",
    padding: "30px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

const Topnav = ({fetchData, cardItems, open,setOpen}) => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  // const [cardItems, setCardItems] = useState([]);
  const company_id = JSON.parse(localStorage.getItem("user"))?.companyId;
  
  const [active, setActive] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleClick = () => {
    setActive(!active);
    setSidebarOpen(!sidebarOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fetchData = async () => {
  //   try {
  //     const result = await CARTGETAPI(company_id);
  //     if (result && result?.res.status === true) {
  //       setCardItems(result?.res?.data?.cartItemResponseList);
  //       const totalAmount = result?.res?.data.reduce(
  //         (total, item) => total + item.price,
  //         0
  //       );
  //     }
  //   } catch (error) {
  //     toast.error(error || error.message);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, [open]);

  const handleDelete = async (id) => {
    try {
      const result = await CARTDELTEAPI(id);
      if (result.res.status == true) {
        toast.error(result.res.message);
        fetchData();
      }
    } catch (error) {
      toast.error(error || error.message);
    }
  };
  // console.log(cardItems,"cardItems")
  return (
    <div className={styles.maintopnav}>
      <Link to="/">
        <div className="topnavimg">
          <img src={logo} alt="" />
        </div>
      </Link>
      <div className={styles.menu_icon} onClick={handleClick}>
        <img src={active ? Cross : Ham} alt="Ham" />
        {active && (
       
       <Sidenav />
        
      )}
      </div>
      
      {/* <div className={styles.menu_iconlist} onClick={handleClick}>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure, rem.</p>
      </div> */}
      <div className={styles.navimg}>
        {/* <img src={search} alt="" /> */}
        <img
          src={cart}
          alt=""
          onClick={handleDrawerOpen}
          className={styles.addcart}
        />
        <span className={styles.cartNumber}>{cardItems.length}</span>
        {/* <img src={cart} alt="" onClick={handleDrawerOpen} className={styles.addcart} /> */}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* <SidebarRegister handleDrawerClose={handleDrawerClose} /> */}
          <SidebarRegister
            handleDrawerClose={handleDrawerClose}
            data={cardItems}
            handleDelete={handleDelete}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default Topnav;
