import React, { useState } from "react";

const AuthContext = React.createContext({
  isSuperAdmin: false,
  token: "",
  refreshToken: "",
  isLoggedIn: false,
  login: (token, refreshToken) => {},
  logout: () => {},
  setUser: (data) => {},
  user: "",
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialRefreshToken = localStorage.getItem("refreshToken");
  const initialUser = localStorage.getItem("user");
  const [token, setToken] = useState(initialToken);
  const [user, setuser] = useState(initialUser);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const userIsLoggedIn = !!token;

  const loginHandler = (data) => {
    setToken(data?.accessToken);
    setRefreshToken(data?.refreshToken);
    setUser(data);
    localStorage.setItem("token", data?.token);
    localStorage.setItem("refreshToken", data?.refreshToken);
  };
  const logoutHandler = () => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("form");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("ALLDATA");
    localStorage.removeItem("userID");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isSuperAdmin");
  };
  const setUser = (data) => {
    setuser(data);
  };
  const contextValue = {
    token: token,
    refreshToken: refreshToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    setUser: setUser,
    user: user,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;