import React from 'react'

function Loader({ loading }) {
  return (
    <div>
      {
        loading && <div className='loadingSpinnerOverlay'>
          <div className='loadingSpinnerContainer'>
            <div className='loadingSpinner'></div>
          </div>
        </div>
      }

    </div>
  )
}

export default Loader