import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "./Utils/auth-context";
import NetworkServices from "./Utils/network-services";
import "./Assets/global.css";


import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: 'c4bc00e9-155f-465a-b25b-64567288c530',
    
    authority: `https://login.microsoftonline.com/096e2142-5ee9-4996-8dd5-4a866345af22`,
  },
};

const pca = new PublicClientApplication(configuration);


NetworkServices();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <MsalProvider instance={pca}>
    <AuthContextProvider>
      <Router>
        <App />
      </Router>
    </AuthContextProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
