import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// import './NotFound.css'; // Create a separate CSS file if you want custom styles

function NotFound() { 
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate
    const errorData = location.state?.errorData;
    console.log(errorData,"errorMessage")
    return (
        <div className="not-found-container">
            <h1>404</h1>
            {
                errorData ? <p>{errorData.message}</p> : <p>Oops! The page you are looking for does not exist.</p> 
            }
            
            <Link to="/">Go Back to Home</Link>
            {/* <button onClick={() => navigate(-1)}>Go Back</button> */}
        </div>
    );
}

export default NotFound;
