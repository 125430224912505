import React from "react";
import Servicewiz from "../../Components/Common/servicewizards";
import Serviceform from "../../Components/Common/wizardform";
import Formassis from "../../Components/Common/formassistant";

const index = () => {
  return (
    <div>
      <Servicewiz />
      <Formassis />
      {/* <Serviceform /> */}
    </div>
  );
};

export default index;
