import React from "react";
import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa"; // Import the specific icons you want to use
import styles from "../empratings/empratings.module.css";
function Staremp(props) {
  const { readonly, count, name, handle } = props;
  const handleClick = (val) => {
    handle(name, val);
  };

  return (
    <div className="cursor-pointer">
      <Rating
        initialRating={count}
        stop={5}
        onClick={handleClick}
        readonly={readonly}
        emptySymbol={<FaRegStar className={styles.icon} />}
        fullSymbol={<FaStar className={styles.icon} />}
      />
    </div>
  );
}

export default Staremp;
