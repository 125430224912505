import React from "react";
import style from "../../Common/Ongoingservice/ongoingservice.module.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { red } from "@material-ui/core/colors";

const Example = (props) => {
  const { maxCompleted, completed } = props;
  return (
    <div className={style.progress}>
      <ProgressBar
        isLabelVisible={false}
        completed={completed}
        maxCompleted={maxCompleted}
        bgColor="#c0902c"
        baseBgColor="#ecd8af"
        labelSize="12px"
      />
    </div>
  );
};

export default Example;
