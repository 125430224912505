import React, { useState, useEffect } from "react";
import Selectedservice from "../../../Components/Common/servicescope";
import Servicereview from "../../../Components/Common/servicereview";
import Addtocart from "../../../Components/Common/Addtocart";
import { GETSERVICEApi } from "../../../Utils/services";
import { useLocation, useParams } from "react-router-dom";


const SelectedService = () => {
  const [allData, setAllData] = useState({})
  const { id } = useParams()
  let { state } = useLocation();
  console.log(state,"state")
  const fetchData = async () => {
    try {
      const result = await GETSERVICEApi(id);
      if (result && result?.res.status === true) {
        setAllData(result?.res?.data)
      }
      else {

      }
    } catch (error) {

      // Fallback to test data
    }

  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="overeview">
        <Addtocart data={allData} state={state} />
        <Selectedservice data={allData} />
        <Servicereview data={allData} />
      </div>
    </>

  )
}

export default SelectedService
