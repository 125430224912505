import React from "react";
import styles from "../servicereview/servicereview.module.css";
import Clientratings from "../../../Components/Common/clientratings";

const index = ({ data }) => {

  return (
    <>
      <div>
        <h3>Reviews</h3>
      </div>
      <div className="row">
        {data?.reviewList?.map((res) => {
          return (
            <div className="col-lg-6 pr-12">
              <div className={styles.servereviewcard}>
                <div className={styles.serimg}>
                  <img src="" alt="" />
                </div>
                <div className={styles.serdetail}>
                  <div className={styles.clrate}>
                    <span className={styles.clrhead}>{res?.title}</span>
                    <Clientratings readonly={true} count={res?.taskRating} />
                  </div>
                  <div>
                    <span className={styles.clrdesc}>{res?.reviewText}</span>
                  </div>
                  <div>
                    <span className={styles.clrdetail}>{res?.reviewText}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default index;
