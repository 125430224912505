import { Formik, Form } from 'formik'
import React, {useContext, useEffect, useState } from 'react'
import FormControl from '../../Components/Common/controls/formcontrol'
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import { GetPatServiceApi, GETSUBSCRPTIONAPI } from '../../Utils/services';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../Components/Common/Loader';
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Button } from "@material-ui/core";
import MoreInfo from '../../Components/Common/MoreInfo';
import AuthContext from '../../Utils/auth-context';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
    ({ theme }) => ({
        border: `5px solid ${theme.palette.divider}`,
        borderRadius: "25px",
        borderColor: "rgba(255, 255, 255, .05)",

        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(270deg)",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
            transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
            marginRight: theme.spacing(5),
        },
    })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));
const useStyles = makeStyles((theme) => ({
    root: {},
    hide: {
        display: "none",
    },

    drawerPaper: {
        backgroundColor: "#FDFCFB",
        padding: "30px",
        width: "100%"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
}));
function Billing() {
    const classes = useStyles();
    const [loading, setloading] = useState(false)
    const [activeTab, setActiveTab] = useState('Quarterly');
    const [selected, setSelected] = useState({});
    const [value, setvalue] = useState()
    const [id, setId] = useState()
    const [details, setDetails] = useState({})
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext);
    const data = authCtx?.user;

    const options = [
        {
            "id": 4,
            "label": "Essentials",
            "value": "Essentials",
            "_value": " 500   ",
            "_value2": " 400    ",
            "categoryinfo": [
                {
                    "id": 1,
                    "label": "Assets",
                    "value": "Assets",
                    "Data": [
                        {
                            "id": 1,
                            "label": "Desktops/Laptops",
                            "value": 20
                        },
                        {
                            "id": 2,
                            "label": "Urls",
                            "value": 1
                        },
                        {
                            "id": 3,
                            "label": "Networks",
                            "value": 1
                        },
                        {
                            "id": 4,
                            "label": "Servers",
                            "value": 0
                        },
                        {
                            "id": 5,
                            "label": "Databases",
                            "value": 0
                        },
                        {
                            "id": 6,
                            "label": "Email",
                            "value": 0
                        },
                        {
                            "id": 7,
                            "label": "Mobiles",
                            "value": 0
                        },
                        {
                            "id": 8,
                            "label": "Public Cloud",
                            "value": 0
                        },
                        {
                            "id": 9,
                            "label": "Web-Application",
                            "value": 0
                        },
                        {
                            "id": 10,
                            "label": "Clusters",
                            "value": 0
                        }
                    ]

                },
                {
                    "id": 2,
                    "label": "Detection",
                    "value": "Detection",
                    "Data": [
                        {
                            "id": 1,
                            "label": "OS-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 2,
                            "label": "Network - Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 3,
                            "label": "Application-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 4,
                            "label": "Anti-Virus and Anti-Malware",
                            "value": ""
                        },
                        {
                            "id": 5,
                            "label": "Intrusion Detection",
                            "value": ""
                        },
                        {
                            "id": 6,
                            "label": "Attack Surface Enumeration",
                            "value": ""
                        },
                        {
                            "id": 7,
                            "label": "Data Leaks",
                            "value": ""
                        },
                        {
                            "id": 8,
                            "label": "DarkWeb",
                            "value": ""
                        },
                        {
                            "id": 9,
                            "label": "Email Phishing",
                            "value": ""
                        },
                        {
                            "id": 10,
                            "label": "Cloud Security Configurations",
                            "value": ""
                        },
                        {
                            "id": 11,
                            "label": "Zero-Day Vulnerabilities",
                            "value": ""
                        },
                        {
                            "id": 12,
                            "label": "Application RunTime Security",
                            "value": ""
                        },
                        {
                            "id": 13,
                            "label": "Logging - Events",
                            "value": ""
                        },
                        {
                            "id": 14,
                            "label": "Logging - Anomaly",
                            "value": ""
                        },
                        {
                            "id": 15,
                            "label": "Regulatory Compliance",
                            "value": ""
                        }
                    ]

                },
                {
                    "id": 3,
                    "label": "Protection",
                    "value": "Protection",
                    "Data": [
                        {
                            "label": "DDoS",
                            "value": "Yes"
                        },
                        {
                            "label": "Email",
                            "value": ""
                        },
                        {
                            "label": "Intrusion Prevention",
                            "value": ""
                        },
                        {
                            "label": "Device & File Integrity",
                            "value": ""
                        },
                        {
                            "label": "Website/WebApplication Integrity",
                            "value": ""
                        },
                        {
                            "label": "Public Cloud Security Posture Management",
                            "value": ""
                        },
                        {
                            "label": "Employee Security Awareness Simulations",
                            "value": ""
                        },
                        {
                            "label": "BCP & DRP",
                            "value": ""
                        },
                        {
                            "label": "DevOps Security",
                            "value": ""
                        },
                        {
                            "label": "Runtime Application Security Protection",
                            "value": ""
                        },
                        {
                            "label": "Firewall Management",
                            "value": ""
                        },
                        {
                            "label": "VPN Management",
                            "value": ""
                        }
                    ]
                },
                {
                    "id": 4,
                    "label": "Response",
                    "value": "Response",
                    "Data": [
                        {
                            "label": "Alerts & Notifications",
                            "value": "Real-Time"
                        },
                        {
                            "label": "Incident Resolution",
                            "value": "SLA^"
                        },
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": ""
                        },
                        {
                            "label": "Anomaly Detection",
                            "value": ""
                        },
                        {
                            "label": "Investigation & Analysis: Isolation & RCA",
                            "value": ""
                        },

                    ]
                },
                {
                    "id": 5,
                    "label": "Training & Awareness",
                    "value": "Training & Awareness",
                    "Data": [
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": "Quarterly"
                        },
                        {
                            "label": "Internet, Phishing and Email Security",
                            "value": "Half-Yearly"
                        },
                        {
                            "label": "Security Policies & Vulnerability Management",
                            "value": "_"
                        },
                        {
                            "label": "Compliance",
                            "value": "_"
                        },
                        {
                            "label": "Security Leadership",
                            "value": "_"
                        }
                    ]

                },
                {
                    "id": 6,
                    "label": "Reporting",
                    "value": "Reporting",
                    "Data":
                        [
                            {
                                "label": "Vulnerability Status",
                                "value": "Monthly"
                            },
                            {
                                "label": "Dashboards & Reports",
                                "value": "Monthly"
                            },
                            {
                                "label": "Indicators of Compromise",
                                "value": ""
                            },
                            {
                                "label": "Email Phishing",
                                "value": ""
                            },
                            {
                                "label": "Incident Management",
                                "value": ""
                            },
                            {
                                "label": "Risk Exposure Status",
                                "value": ""
                            },
                            {
                                "label": "Security Audits",
                                "value": ""
                            },
                            {
                                "label": "Employee Security Awareness",
                                "value": ""
                            },
                            {
                                "label": "Overall Security Performance",
                                "value": ""
                            },
                            {
                                "label": "Compliance Status",
                                "value": ""
                            },
                            {
                                "label": "Heat Maps",
                                "value": ""
                            },
                            {
                                "label": "Policy Violations",
                                "value": ""
                            }
                        ]


                },
                {
                    "id": 7,
                    "label": "Service",
                    "value": "Service",
                    "Data":
                        [
                            {
                                "label": "Collaboration via",
                                "value": "Emails"
                            },
                            {
                                "label": "Service Type",
                                "value": "Self-Service"
                            },
                            {
                                "label": "Current State Assessment",
                                "value": "No Commitment"
                            },
                            {
                                "label": "^Issue Response Time",
                                "value": "48-Hrs"
                            },
                            {
                                "label": "Problem Elicitation",
                                "value": "Best Effort"
                            },
                            {
                                "label": "Issue Remedy~",
                                "value": "Best Effort"
                            },
                            {
                                "label": "Security - Technical Assistance Level",
                                "value": "Competent"
                            },
                            {
                                "label": "Cybersecurity Best Practices & Controls",
                                "value": "Best Fit"
                            },
                            {
                                "label": "Solution Detailing*",
                                "value": ""
                            },
                            {
                                "label": "Project Management Methodology",
                                "value": ""
                            },
                            {
                                "label": "Initiatives Driven by",
                                "value": " "
                            },
                            {
                                "label": "SecOps & IR Management Assistance Level",
                                "value": ""
                            },
                            {
                                "label": "Standard Operating Procedure",
                                "value": ""
                            },
                            {
                                "label": "Cybersecurity Maturity Program",
                                "value": ""
                            },
                            {
                                "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                                "value": ""
                            },
                            {
                                "label": "Breach Response Management***",
                                "value": ""
                            }
                        ]



                },

            ]

        },
        {
            "id": 1,
            "label": "Operational",
            "value": "Operational",
            "_value": " 2000  ",
            "_value2": " 1600   ",

            "categoryinfo": [
                {
                    "id": 1,
                    "label": "Assets",
                    "value": "Assets",
                    "Data": [
                        {
                            "id": 1,
                            "label": "Desktops/Laptops",
                            "value": 100
                        },
                        {
                            "id": 2,
                            "label": "Urls",
                            "value": 3
                        },
                        {
                            "id": 3,
                            "label": "Networks",
                            "value": 2
                        },
                        {
                            "id": 4,
                            "label": "Servers",
                            "value": 1
                        },
                        {
                            "id": 5,
                            "label": "Databases",
                            "value": 0
                        },
                        {
                            "id": 6,
                            "label": "Email",
                            "value": 0
                        },
                        {
                            "id": 7,
                            "label": "Mobiles",
                            "value": 0
                        },
                        {
                            "id": 8,
                            "label": "Public Cloud",
                            "value": 0
                        },
                        {
                            "id": 9,
                            "label": "Web-Application",
                            "value": 0
                        },
                        {
                            "id": 10,
                            "label": "Clusters",
                            "value": 0
                        }
                    ]

                },
                {
                    "id": 2,
                    "label": "Detection",
                    "value": "Detection",
                    "Data": [
                        {
                            "id": 1,
                            "label": "OS-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 2,
                            "label": "Network - Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 3,
                            "label": "Application-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 4,
                            "label": "Anti-Virus and Anti-Malware",
                            "value": "Yes"
                        },
                        {
                            "id": 5,
                            "label": "Intrusion Detection",
                            "value": "Yes"
                        },
                        {
                            "id": 6,
                            "label": "Attack Surface Enumeration",
                            "value": "Yes"
                        },
                        {
                            "id": 7,
                            "label": "Data Leaks",
                            "value": "Yes"
                        },
                        {
                            "id": 8,
                            "label": "DarkWeb",
                            "value": "Yes"
                        },
                        {
                            "id": 9,
                            "label": "Email Phishing",
                            "value": "Yes"
                        },
                        {
                            "id": 10,
                            "label": "Cloud Security Configurations",
                            "value": "Yes"
                        },
                        {
                            "id": 11,
                            "label": "Zero-Day Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "id": 12,
                            "label": "Application RunTime Security",
                            "value": "Yes"
                        },
                        {
                            "id": 13,
                            "label": "Logging - Events",
                            "value": "Yes"
                        },
                        {
                            "id": 14,
                            "label": "Logging - Anomaly",
                            "value": "Yes"
                        },
                        {
                            "id": 15,
                            "label": "Regulatory Compliance",
                            "value": "Yes"
                        }
                    ]

                },
                {
                    "id": 3,
                    "label": "Protection",
                    "value": "Protection",
                    "Data": [
                        {
                            "label": "DDoS",
                            "value": "Yes"
                        },
                        {
                            "label": "Email",
                            "value": ""
                        },
                        {
                            "label": "Intrusion Prevention",
                            "value": ""
                        },
                        {
                            "label": "Device & File Integrity",
                            "value": ""
                        },
                        {
                            "label": "Website/WebApplication Integrity",
                            "value": ""
                        },
                        {
                            "label": "Public Cloud Security Posture Management",
                            "value": ""
                        },
                        {
                            "label": "Employee Security Awareness Simulations",
                            "value": ""
                        },
                        {
                            "label": "BCP & DRP",
                            "value": ""
                        },
                        {
                            "label": "DevOps Security",
                            "value": ""
                        },
                        {
                            "label": "Runtime Application Security Protection",
                            "value": ""
                        },
                        {
                            "label": "Firewall Management",
                            "value": ""
                        },
                        {
                            "label": "VPN Management",
                            "value": ""
                        }
                    ]
                },
                {
                    "id": 4,
                    "label": "Response",
                    "value": "Response",
                    "Data": [
                        {
                            "label": "Alerts & Notifications",
                            "value": "Real-Time"
                        },
                        {
                            "label": "Incident Resolution",
                            "value": "SLA^"
                        },
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": "Yes"
                        },
                        {
                            "label": "Anomaly Detection",
                            "value": ""
                        },
                        {
                            "label": "Investigation & Analysis: Isolation & RCA",
                            "value": ""
                        },

                    ]
                },
                {
                    "id": 5,
                    "label": "Training & Awareness",
                    "value": "Training & Awareness",
                    "Data": [
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": "Quarterly"
                        },
                        {
                            "label": "Internet, Phishing and Email Security",
                            "value": "Half-Yearly"
                        },
                        {
                            "label": "Security Policies & Vulnerability Management",
                            "value": "_"
                        },
                        {
                            "label": "Compliance",
                            "value": "_"
                        },
                        {
                            "label": "Security Leadership",
                            "value": "_"
                        }
                    ]

                },
                {
                    "id": 6,
                    "label": "Reporting",
                    "value": "Reporting",
                    "Data":
                        [
                            {
                                "label": "Vulnerability Status",
                                "value": "Monthly"
                            },
                            {
                                "label": "Dashboards & Reports",
                                "value": "Monthly"
                            },
                            {
                                "label": "Indicators of Compromise",
                                "value": "_"
                            },
                            {
                                "label": "Email Phishing",
                                "value": "_"
                            },
                            {
                                "label": "Incident Management",
                                "value": "_"
                            },
                            {
                                "label": "Risk Exposure Status",
                                "value": "_"
                            },
                            {
                                "label": "Security Audits",
                                "value": "_"
                            },
                            {
                                "label": "Employee Security Awareness",
                                "value": "_"
                            },
                            {
                                "label": "Overall Security Performance",
                                "value": "_"
                            },
                            {
                                "label": "Compliance Status",
                                "value": "_"
                            },
                            {
                                "label": "Heat Maps",
                                "value": "_"
                            },
                            {
                                "label": "Policy Violations",
                                "value": "_"
                            }
                        ]


                },
                {
                    "id": 7,
                    "label": "Service",
                    "value": "Service",
                    "Data":
                        [
                            {
                                "label": "Collaboration via",
                                "value": ""
                            },
                            {
                                "label": "Service Type",
                                "value": "CRM"
                            },
                            {
                                "label": "Current State Assessment",
                                "value": "Administered"
                            },
                            {
                                "label": "^Issue Response Time",
                                "value": "Questionnaire"
                            },
                            {
                                "label": "Problem Elicitation",
                                "value": "24-Hrs"
                            },
                            {
                                "label": "Issue Remedy~",
                                "value": "Facilitated"
                            },
                            {
                                "label": "Security - Technical Assistance Level",
                                "value": "Temporary"
                            },
                            {
                                "label": "Cybersecurity Best Practices & Controls",
                                "value": "Competent"
                            },
                            {
                                "label": "Solution Detailing*",
                                "value": "Best Fit"
                            },
                            {
                                "label": "Project Management Methodology",
                                "value": "High Level"
                            },
                            {
                                "label": "Initiatives Driven by",
                                "value": "Guidance Only"
                            },
                            {
                                "label": "SecOps & IR Management Assistance Level",
                                "value": "Customer"
                            },
                            {
                                "label": "Standard Operating Procedure",
                                "value": "Competent"
                            },
                            {
                                "label": "Cybersecurity Maturity Program",
                                "value": ""
                            },
                            {
                                "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                                "value": ""
                            },
                            {
                                "label": "Breach Response Management***",
                                "value": ""
                            }
                        ]



                },

            ]

        },
        {
            "id": 2,
            "label": "Tactical",
            "value": "Tactical",
            "_value": " 4500 ",
            "_value2": " 3600    ",
            "categoryinfo": [
                {
                    "id": 1,
                    "label": "Assets",
                    "value": "Assets",
                    "Data": [
                        {
                            "id": 2,
                            "label": "Desktops/Laptops",
                            "value": 500
                        },
                        {
                            "id": 3,
                            "label": "URLs",
                            "value": 5
                        },
                        {
                            "id": 4,
                            "label": "Networks",
                            "value": 3
                        },
                        {
                            "id": 5,
                            "label": "Servers",
                            "value": 3
                        },
                        {
                            "id": 6,
                            "label": "Databases",
                            "value": 1
                        },
                        {
                            "id": 7,
                            "label": "Email",
                            "value": 500
                        },
                        {
                            "id": 8,
                            "label": "Mobiles",
                            "value": 100
                        },
                        {
                            "id": 9,
                            "label": "Public Cloud",
                            "value": 1
                        },
                        {
                            "id": 10,
                            "label": "Web-Application",
                            "value": 1
                        },
                        {
                            "id": 11,
                            "label": "Clusters",
                            "value": 1
                        },
                        {
                            "id": 12,
                            "label": "Detection",
                            "value": "Optimum"
                        },
                        {
                            "id": 13,
                            "label": "Protection",
                            "value": "Optimum"
                        },
                        {
                            "id": 14,
                            "label": "Response",
                            "value": "SLA"
                        },
                        {
                            "id": 15,
                            "label": "Reporting",
                            "value": "Fortnightly / Live"
                        },
                        {
                            "id": 16,
                            "label": "Training & Awareness",
                            "value": "Monthly"
                        }
                    ]
                },
                {
                    "id": 2,
                    "label": "Detection",
                    "value": "Detection",
                    "Data": [
                        {
                            "label": "OS-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "label": "Network - Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "label": "Application-Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "label": "Anti-Virus and Anti-Malware",
                            "value": "Yes"
                        },
                        {
                            "label": "Intrusion Detection",
                            "value": "Yes"
                        },
                        {
                            "label": "Attack Surface Enumeration",
                            "value": "Yes"
                        },
                        {
                            "label": "Data Leaks",
                            "value": "Yes"
                        },
                        {
                            "label": "DarkWeb",
                            "value": "Yes"
                        },
                        {
                            "label": "Email Phishing",
                            "value": "Yes"
                        },
                        {
                            "label": "Cloud Security Configurations",
                            "value": "Yes"
                        },
                        {
                            "label": "Zero-Day Vulnerabilities",
                            "value": "Yes"
                        },
                        {
                            "label": "Application RunTime Security",
                            "value": ""
                        },
                        {
                            "label": "Logging - Events",
                            "value": ""
                        },
                        {
                            "label": "Logging - Anomaly",
                            "value": ""
                        },
                        {
                            "label": "Regulatory Compliance",
                            "value": ""
                        }
                    ]
                },
                {
                    "id": 3,
                    "label": "Protection",
                    "value": "Protection",
                    "Data": [
                        {
                            "label": "DDoS",
                            "value": "Yes"
                        },
                        {
                            "label": "Email",
                            "value": "Yes"
                        },
                        {
                            "label": "Intrusion Prevention",
                            "value": "Yes"
                        },
                        {
                            "label": "Device & File Integrity",
                            "value": "Yes"
                        },
                        {
                            "label": "Website/WebApplication Integrity",
                            "value": "Yes"
                        },
                        {
                            "label": "Public Cloud Security Posture Management",
                            "value": "Yes"
                        },
                        {
                            "label": "Employee Security Awareness Simulations",
                            "value": "Yes"
                        },
                        {
                            "label": "BCP & DRP",
                            "value": "Yes"
                        },
                        {
                            "label": "DevOps Security",
                            "value": "Yes"
                        },
                        {
                            "label": "Runtime Application Security Protection",
                            "value": "Yes"
                        },
                        {
                            "label": "Firewall Management",
                            "value": "Yes"
                        },
                        {
                            "label": "VPN Management",
                            "value": "Yes"
                        }
                    ]
                },
                {
                    "id": 4,
                    "label": "Response",
                    "value": "Response",
                    "Data": [
                        {
                            "label": "Alerts & Notifications",
                            "value": "Real-Time"
                        },
                        {
                            "label": "Incident Resolution",
                            "value": "SLA^"
                        },
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": "Yes"
                        },
                        {
                            "label": "Anomaly Detection",
                            "value": ""
                        },
                        {
                            "label": "Investigation & Analysis: Isolation & RCA",
                            "value": ""
                        },

                    ]
                },
                {
                    "id": 5,
                    "label": "Training & Awareness",
                    "value": "Training & Awareness",
                    "Data": [
                        {
                            "label": "Incident Triage: Severity & Prioritization",
                            "value": "Monthly"
                        },
                        {
                            "label": "Internet, Phishing and Email Security",
                            "value": "Monthly"
                        },
                        {
                            "label": "Security Policies & Vulnerability Management",
                            "value": "Quarterly"
                        },
                        {
                            "label": "Compliance",
                            "value": "Quarterly"
                        },
                        {
                            "label": "Security Leadership",
                            "value": "Quarterly"
                        }
                    ]

                },
                {
                    "id": 6,
                    "label": "Reporting",
                    "value": "Reporting",
                    "Data":
                        [
                            {
                                "label": "Vulnerability Status",
                                "value": "Monthly"
                            },
                            {
                                "label": "Dashboards & Reports",
                                "value": "Fortnightly / Live"
                            },
                            {
                                "label": "Indicators of Compromise",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Email Phishing",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Incident Management",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Risk Exposure Status",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Security Audits",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Employee Security Awareness",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Overall Security Performance",
                                "value": "Quarterly"
                            },
                            {
                                "label": "Compliance Status",
                                "value": "_"
                            },
                            {
                                "label": "Heat Maps",
                                "value": "_"
                            },
                            {
                                "label": "Policy Violations",
                                "value": "_"
                            }
                        ]


                },
                {
                    "id": 7,
                    "label": "Service",
                    "value": "Service",
                    "Data":
                        [
                            {
                                "label": "Collaboration via",
                                "value": "CRM"
                            },
                            {
                                "label": "Service Type",
                                "value": "Managed"
                            },
                            {
                                "label": "Current State Assessment",
                                "value": "Tool/Technology Based"
                            },
                            {
                                "label": "^Issue Response Time",
                                "value": "24-Hrs"
                            },
                            {
                                "label": "Problem Elicitation",
                                "value": "5 (Why-Because)s"
                            },
                            {
                                "label": "Issue Remedy~",
                                "value": "Stable"
                            },
                            {
                                "label": "Security - Technical Assistance Level",
                                "value": "Expert Authoritative"
                            },
                            {
                                "label": "Cybersecurity Best Practices & Controls",
                                "value": "Technology Best"
                            },
                            {
                                "label": "Solution Detailing*",
                                "value": "Design Level"
                            },
                            {
                                "label": "Project Management Methodology",
                                "value": "Sprints"
                            },
                            {
                                "label": "Initiatives Driven by",
                                "value": "Customer & fnCyber"
                            },
                            {
                                "label": "SecOps & IR Management Assistance Level",
                                "value": "Competent"
                            },
                            {
                                "label": "Standard Operating Procedure",
                                "value": "Documented"
                            },
                            {
                                "label": "Cybersecurity Maturity Program",
                                "value": ""
                            },
                            {
                                "label": "Security Governance (Policy/Compliance/Risk Mgmt)",
                                "value": ""
                            },
                            {
                                "label": "Breach Response Management***",
                                "value": ""
                            }
                        ]



                },
            ]

        },


      

    ]
    const [open, setOpen] = useState(false);
    const [addOn, setAddOn] = useState([]);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const category = localStorage.getItem('catId')
    const fetchData = async () => { 
        setloading(true);

        try {
            if (value) { 
                const result = await GetPatServiceApi(value);
                console.log(result?.res?.status,"result?.res?.status")
                if (result?.res) {
                    console.log(result?.res?.status,"result?.res?.status")
                    // const _data = result.res.data?.[0]?.categoryServices?.map((res) => ({
                        const _data = result.res?.map((res) => ({
                            id: res?.serviceId,
                            label: res?.serviceName,
                            value: res?.serviceName
                    })) || [];

                    setAddOn(_data);
                }
            } else{
                const result = await GetPatServiceApi(category);
                console.log(result?.res?.status,"result?.res?.status")
                if (result?.res) {
                    console.log(result?.res?.status,"result?.res?.status")
                    // const _data = result.res.data?.[0]?.categoryServices?.map((res) => ({
                        const _data = result.res?.map((res) => ({
                            id: res?.serviceId,
                            label: res?.serviceName,
                            value: res?.serviceName
                    })) || [];

                    setAddOn(_data);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getPlan = () => {
        try {
            setloading(true)
            if (localStorage.getItem('catId') && localStorage.getItem('planId')) {
                const data = {
                    category: localStorage.getItem('catId'),
                    plan: localStorage.getItem('planId'),
                }

                setSelected(data);
                const filter_data = options?.filter((res) => res.id == localStorage.getItem('catId'));
                console.log(filter_data[0],"filter_data[0]")
                console.log(filter_data,"filter_data")
                setSelected((res =>
                ({
                    ...res,
                    "filter_data": filter_data[0]


                })))

                setloading(false)
            }
            else {
                setloading(false)
            }

        }
        catch (err) {
            setloading(false)
        }

    }


    // const category = localStorage.getItem('catId')


    const handleSubmit = (values) => { 
        setloading(true)
        if (values.formdata.check1 === true && values.formdata.check2 == true) {
            const UserData = JSON.parse(localStorage.getItem('user'));
            const data = {
                ...values.formdata, 'totalCount': activeTab == 'Quarterly' ? 3 : 12, 'customerId': UserData?.userId, 'companyId': UserData?.companyId, 'planId': localStorage.getItem('planId'),'categoryId': localStorage.getItem('plans_cat')
            }

            navigate('/billingform', { state: { yourData: data } });
            setloading(false)
        }
        else {
            toast.error("Enable Checkbox")
            setloading(false)
        }

    }
    const filterData = () => {
        setloading(true)
        if (value) {
            console.log("run value")
            const filter_data = options?.filter((res) => res.id == value);
            setSelected((res =>
            ({
                ...res,
                "filter_data": filter_data[0]

            })))
            setloading(false)
        }
        else {
            console.log("run")
            const filter_data = options?.filter((res) => res.id == selected?.category);
            // setSelected((res =>
            // ({
            //     ...res,
            //     "filter_data": filter_data[0]


            // })));
            setloading(false)
        }

    }

    const fetchaAllData = async () => {
        setloading(true);
        setId()
        const isJSONString = (str) => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };
        if (isJSONString(authCtx?.user)) {
            const data = JSON?.parse(authCtx?.user);
            try {
                const _result = await GETSUBSCRPTIONAPI(data?.userId);
                if (_result.res.status == true) {
                    setDetails(_result.res.data);
                    setId(_result.res.data?.serviceId)
                    // setvalue(_result.res.data.categoryId)
                    setloading(false);
                }
                else {
                    setloading(false);
                }

            }
            catch (err) {
                console.log(err)
                setloading(false);
            }
        } else {
            const data = authCtx?.user;
            try {
                const _result = await GETSUBSCRPTIONAPI(data?.userId);
                if (_result.res.status == true) {
                    setDetails(_result.res.data);
                    setId(_result.res.data?.serviceId);
                    // setvalue(_result.res.data.categoryId)
                    setloading(false);
                }
                else {
                    setloading(false);
                }
            }
            catch (err) {
                console.log(err)
                setloading(false);
            }
        }





    }

console.log(value,"value")

    useEffect(() => {
        fetchaAllData();

    }, [data?.userId]);
    useEffect(() => {
        filterData()
    }, [value]);
    useEffect(() => {
        getPlan();


    }, [category])
    useEffect(() => {
        fetchData();

    }, [category, value]);
    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }

    function redirecturl() {
        window.open("https://www.fncyber.com/pricing/", "_blank");
    }
    console.log(selected,"selectedselected")
    return (
      <div className="overview _h_100 d-flex justify-content-center align-items-center">
        <Loader loading={loading} />
        <Formik
          enableReinitialize={true}
          initialValues={{
            formdata: {
              serviceId: "",
              serviceIdselected: selected.category ? selected.category : value,
              check1: false,
              check2: false,
              customerId: "",
              planId: selected?.plan,
              totalCount: 3,
              companyId: 7,
            },
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form className="col-lg-10">
              <div className="row">
                {/* <div className="plan-change mb-5">
                <div
                              className="px-4 py-2 Monthly active mr-5"
                              onClick={redirecturl}
                            >
                              <button type="submit">Change Plan</button>
                            </div>
                </div> */}
                <div className="d-flex justify-content-between align-items-start">
                  <div className="col-lg-6">
                    <div className="plan_wrapper">
                      <div className="plan_head">
                        <h1>Plan Details</h1>
                      </div>

                      <div>
                        <div>
                          <FormControl
                            control="Select"
                            options={options}
                            name="formdata.serviceIdselected"
                            prevalue={formik.values.formdata.serviceIdselected}
                            selected={setvalue}
                            
                          />
                        </div>

                        <div className="mt-5">
                          {selected?.filter_data &&
                            selected?.filter_data.categoryinfo &&
                            selected?.filter_data?.categoryinfo?.map(
                              (result, milestoneIndex) => {
                                return (
                                  <>
                                    <Accordion key={milestoneIndex}>
                                      <AccordionSummary
                                        aria-controls={`panel${milestoneIndex}-content`}
                                        id={`panel${milestoneIndex}-header`}
                                        sx={{ cursor: "unset !important" }}
                                      >
                                        <p>{result?.label}</p>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          <ul>
                                            {result?.Data?.map((res) => {
                                              return (
                                                <li>
                                                  <div className="d-flex justify-content-between ">
                                                    <p>{res?.label}</p>
                                                    <span>{res?.value}</span>
                                                  </div>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  </>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div class="desc_wrapper">
                      <div class="col-lg-10">
                        <div className="p-2 d-flex justify-content-around gap-5 Monthly_wapper ">
                          <div
                            className={`d-flex align-items-center  Monthly ${
                              activeTab === "Quarterly" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Quarterly")}
                          >
                            Quarterly
                          </div>
                          <div
                            className={`d-flex align-items-center  Monthly ${
                              activeTab === "Annual" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Annual")}
                          >
                            Annual{" "}
                            <div className="ml-2 discount20">20% Discount</div>
                          </div>
                        </div>
                        <div className="selected_wrapper">
                          <div className="d-flex">
                            <span>Plan Selected :</span>
                            <span className="ml-2 paln_cat">
                              {selected?.filter_data?.label}
                            </span>
                          </div>
                          <div>
                            <span className="paln_cat cost">
                              ${" "}
                              {activeTab == "Quarterly"
                                ? selected?.filter_data?._value
                                : selected?.filter_data?._value2}{" "}
                              / Month
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="add_on_wrapper my">
                            <div className="mb-3">
                              <FormControl
                                control="Select"
                                options={addOn}
                                name="formdata.serviceId"
                              />
                            </div>

                            <div className="my-2">
                              <FormControl
                                control="Checkbox"
                                label={
                                  "I have read 'Subscription Explained Inside' 'more info'"
                                }
                                name="formdata.check1"
                              />
                            </div>
                            <div>
                              <FormControl
                                control="Checkbox"
                                label={
                                  "I have read and agree to the terms and Conditions"
                                }
                                name="formdata.check2"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end mt-2">
                          <div className="d-flex ">
                          
                            <div
                              className="px-4 py-2 Monthly_  mx-2"
                              onClick={handleDrawerOpen}
                            >
                              <button type="button">More Info</button>
                            </div>
                            <div className="px-4 py-2 Monthly active">
                              <button type="submit">Proceed</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <MoreInfo id={value} handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </div>
    );
}

export default Billing