export const LOGIN = "login";
export const CREATECUSTOMER = "customer/create";
export const GETCUSTOMERONE = "customer/get/";
// export const GETCATEGORYSERVICE = "/task/getByCategory";
export const GETCATEGORYSERVICE = "/serviceMapping/get";
export const GETSERVICE = "/task/get/";
export const GETOWNSERVICE = "/purchase/getAll/";
export const GETOWNSERVICEONE = "/purchase/get/";
export const SENDINVITE = "customer/inviteMember";
export const GETREVIEW = "customer/getReview/customer/";
export const CREATEREVIEW = "/customer/createReview";
export const CARTADD = "/cart/addItem";
export const CARTGET = "/cart/get/";
export const CARTDELTE = "/cart/delete/";
export const PURCHASE = "/purchase/task";
export const REFRESHTOKEN = "/refreshToken";
export const CHECKOUT = "purchase/checkout";
export const CHECKOUTAPIS = "api/checkout";
export const UPDATERACI = "customer/updateRaci";
export const GETCOMMENTONE = "comment/getAll/subMilestone/";
export const UPDATEPROFILE = "/customer/updateProfile";
export const GETPROFILE = "/getProfile";
export const CUSTOMERUPDATE = "/customer/update";
export const GETDOCUMENT = "/document/getAll/";
export const CREATESUBSCRPTION = "api/createSubscription";
export const GETSUBSCRPTION = "api/getPlan/";
export const CANCELSUBSCRPTION = "api/cancelSubscription/";
export const GETADDONS = "/task/getAddons";

// export const EMPLOYEECREATE = "employee/create";
// export const ALLEMPLOYEEGET = "employee/getAll";

// export const EMPLOYEEDELETE = "employee/delete/";
// export const SERVICEADD = "task/create";
// export const SERVICEBYCATEGORY = "task/getByCategory";
// export const SERVICEALL = "task/getAll";
// export const SERVICEONE = "task/get";
// export const DOCUMENTCREATE = "document/create";
// export const LinkCREATE = "link/create";
// export const LinkDelete = "/link/delete/";
// export const GETCUSTOMERALL = "customer/getAll";
// export const GETCUSTOMER = "customer/get";
