import React, { useState } from "react";
import { IconButton, Button, Typography, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import addtocart from "../../../Assets/images/cart.png";
import deleteimg from "../../../Assets/images/delete.png";
import download from "../../../Assets/images/download.png";
import cost from "../../../Assets/images/cost.png";
import duration from "../../../Assets/images/watch.png";
import style from "../Cart/Cart.module.css";
import { toast } from "react-toastify";

// const data = [
//   {
//     id: 1,
//     name: "Ransomware BIA Assessment",
//     title1: "Operational Services",
//     title2: "Estimated duration:",
//   },
//   {
//     id: 2,
//     name: "Ransomware BIA Assessment",
//     title1: "Operational Services",
//     title2: "Estimated duration:",
//   },
//   {
//     id: 3,
//     name: "Ransomware BIA Assessment",
//     title1: "Operational Services",
//     title2: "Estimated duration:",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  closeicon: {
    // textAlign: "right",
    // display: "flex",
    // justifyContent: "space-between",
  },
  box: {
    width: "250px",
    margin: "0px auto",
    textAlign: "center",
  },
  img: {
    width: "100px",
    padding: "30px 0",
  },
  button: {
    marginBottom: "10px",
    background: "#C0902C",
    // width: "219px",
    padding: "16px",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: "#C0902C",
    },
  },
  typography: {
    color: "#091e42",
    fontSize: 14,
    fontWeight: 400,
  },
}));

const SidebarRegister = ({ handleDrawerClose, data, handleDelete }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const hanlde = () => {
    if (data?.length > 0) {
      navigate("/services/purchase");
    } else {
      toast.error("1 item Needed");
    }
  };

  const [quantities, setQuantities] = useState(
    data.reduce((acc, item) => ({ ...acc, [item.cartItemId]: 1 }), {})
  );

  const handleButtonClick = (operation, cartItemId) => {
    console.log(cartItemId,"operation")
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      
      if (operation === "+") {
        newQuantities[cartItemId] = (newQuantities[cartItemId] || 0) + 1;
      } else if (operation === "-" && (newQuantities[cartItemId] || 0) > 0) {
        newQuantities[cartItemId] = (newQuantities[cartItemId] || 0) - 1;
      }

      return newQuantities;
    });
  };
//  console.log(data,"data")
  return (
    <div className={style.maincart}>
      <div className={classes.closeicon}>
        <div className={style.purchaseditems}>
          <div className={style.closecart}>
            <img src={addtocart} alt="" />
            <span className={style.cartimgtext}>Add to cart</span>
          </div>
          <div>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className={style.puchasesum}>
      {data && data.map((purchaseserve) => (
        <div key={purchaseserve.cartItemId} className={style.purchaseservice}>
          <div className={style.purchasetile}>
            <div>
              <h3 className={style.prservicename}>
                {purchaseserve.serviceName}
              </h3>
              <span className={style.cartrservicename}>
                {purchaseserve.categoryName}
              </span>
            </div>
            <div className="btnsection">
              <div className="sp-quantity">
                <div className="sp-minus fff">
                  <a
                    className="ddd"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleButtonClick("-", purchaseserve.cartItemId);
                    }}
                  >
                    -
                  </a>
                </div>
                <div className="sp-input">
                  <input
                    type="text"
                    className="quntity-input"
                    value={quantities[purchaseserve.cartItemId] || 0}
                    readOnly
                  />
                </div>
                <div className="sp-plus fff">
                  <a
                    className="ddd"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleButtonClick("+", purchaseserve.cartItemId);
                    }}
                  >
                    +
                  </a>
                </div>
              </div>
            </div>
            <div className={style.puchaseimg}>
              <div
                className={style.purchasedel}
                onClick={() => handleDelete(purchaseserve.cartItemId)}
              >
                <img src={deleteimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
      </div>
      <div className={style.cartitems}>
        <div className={style.cartbtn}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            href="#contained-buttons"
            onClick={hanlde}
    
          >
            Get estimates & Book
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SidebarRegister };
