import React, { useContext ,useRef } from "react";
import Userupdates from "../../../Components/Common/userupdate/addmember";
import Tables from "../../../Components/Common/table";
import Downloadfiles from "../../../Components/Common/Downloadlinks";
import Comment from "../../../Components/Common/Modals/Comment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GETOWNSERVICEONEAPI, UPDATERACIAPI } from "../../../Utils/services";
import { useMemo } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import Loader from "../../../Components/Common/Loader";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { ModalContext } from "../../../Utils/Context";
import Mainbtn from "../../../Components/Common/button";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
  margin: 10,
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    border: `5px solid ${theme.palette.divider}`,
    borderRadius: "10px",
    borderColor: "rgba(255, 255, 255, .05)",

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(270deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginRight: theme.spacing(5),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Userupdate = () => {
  const [userData, setuserData] = useState({});
  const { id } = useParams();
  const [milstone, setMilestone] = useState([]);
  const [matData, setmatData] = useState([]);
  const [loading, setloading] = useState(false);
  const [expanded, setExpanded] = useState(
    new Array(milstone.length).fill(false)
  );
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const navigate = useNavigate(); 
  const fetchData = async () => {
    setloading(true)
    // Initialize navigate
    try {
      const result = await GETOWNSERVICEONEAPI(id);
      if (result.res.status === true) {
        setloading(false)
        setmatData(result?.res?.data?.projectRACIMatrixList);
        setuserData(result.res.data);
      }
    } catch (error) { 
      console.log(error?.message,"error")
      setloading(false)
      toast.error(error?.message)
      navigate('/not-found', { state: { errorData: error } });

    }
  };
  const handleNameChange = (index, value) => {
    if (matData[index]) {
      const newMatData = [...matData];
      newMatData[index].contactName = value;
      setmatData(newMatData);
    }

    // setmatData(newMatData);
  };
  const handleEmailChange = (index, value) => {
    if (matData[index]) {
      const newMatData = [...matData];
      newMatData[index].raciEmail = value;
      setmatData(newMatData);
    }
  };
  // const handleContactChange = (index, value) => { 
  //   if (matData[index]) {
  //     const newMatData = [...matData];
  //     // console.log(value,"country code")
  //     const newvalue = parsePhoneNumber(value && value)
  //     // console.log(newvalue && newvalue?.countryCallingCode ,"country code")
  //     newMatData[index].phoneNumber = value;
  //     newMatData[index].countryCode = newvalue && newvalue?.countryCallingCode;
  //     setmatData(newMatData);
  //   }
  // };
  const handleContactChange = (index, value) => { 
    if (matData[index]) {
      const newMatData = [...matData];
  
      // Ensure the value is a string before passing it to parsePhoneNumber
      if (typeof value === 'string') {
        const newvalue = parsePhoneNumber(value);
        newMatData[index].phoneNumber = value;
        newMatData[index].countryCode = newvalue?.countryCallingCode;
        // console.log(newvalue,"newvalue")
      } else {
        console.error("Value must be a string");
      }
  
      setmatData(newMatData);
    }
  };
  // const handleContactChangecode = (index, value) => { 
  //   if (matData[index]) {
  //     const newMatData = [...matData];
  //     // newMatData[index].phoneNumber = value;
  //     console.log(value,"country code")
  //     const newvalue = parsePhoneNumber(value)
  //     console.log(newvalue,"country code")
  //     newMatData[index].countryCode = newvalue;
  //     setmatData(newMatData);
  //     console.log(newMatData,"newMatData")
  //   }
  // };
  const COLUMNS = [
    {
      Header: "Stakeholder",
      accessor: "stakeholder",
    },
    {
      Header: "Responsible (R)",
      accessor: "raciR",
      Cell: ({ value }) => (
        <div className="w-100">
          {value === true ? <span>&#10004;</span> : ""}
        </div>
      ),
    },
    {
      Header: "Accountable (A)",
      accessor: "raciA",
      Cell: ({ value }) => (
        <div className="w-100">
          {value === true ? <span>&#10004;</span> : ""}
        </div>
      ),
    },
    {
      Header: "Consulted (C)",
      accessor: "raciC",
      Cell: ({ value }) => (
        <div className="w-100">
          {value === true ? <span>&#10004;</span> : ""}
        </div>
      ),
    },
    {
      Header: "Informed (I)",
      accessor: "raciI",
      Cell: ({ value }) => (
        <div className="w-100">
          {value === true ? <span>&#10004;</span> : ""}
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "contactName",
    },
    {
      Header: "Email",
      accessor: "raciEmail",
    },
    {
      Header: "Country code & Contact",
      accessor: "phoneNumber",
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  useEffect(() => {
    fetchData();
  }, []);
  const handleUpdate = async () => { 
    console.log(matData,"matData")
    setloading(true)
    try {
      const payload = { raciUpdateDto: matData };
      const result = await UPDATERACIAPI(payload);

      if (result.res.status == true) {
        setloading(false)
        toast.success(result.res.message);
        fetchData()
      }
    } catch (error) {
      setloading(false)
      toast.error(error || error.message);
    }
  };
  const handleComent = (id) => {
    const ModdalData = <Comment id={id} closeModal={closeModal} />;
    handleModalData(ModdalData, "md");
  };
  const raciMatrixRef = useRef(null);
  const raciservice = useRef(null);
  const raciComments = useRef(null);
  const raciLinks = useRef(null);
  const scrollToRaciMatrix = () => {
    if (raciMatrixRef.current) {
      raciMatrixRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToService = () => {
    if (raciservice.current) {
      raciservice.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToComments = () => {
    if (raciComments.current) {
      raciComments.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToLinks = () => {
    if (raciLinks.current) {
      raciLinks.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Userupdates data={userData} scrollToRaciMatrix={scrollToRaciMatrix} scrollToService={scrollToService} scrollToComments={scrollToComments} scrollToLinks={scrollToLinks} />
      {/* {userData?.projectRACIMatrixList && (
        <Tables columns={columns} data={userData?.projectRACIMatrixList} />
      )} */}
      <div className="px-5">
        <div className="col-lg-12 mt-5 text_left">
          <div>
            
            {/* <span>Stakeholders required to be involved in the RBIA.</span> */}
            <div class="tooltip-container2">
              <h3 ref={raciMatrixRef}>RACI Matix</h3>
              <div class="tooltip-container">
  <button class="info-button">i</button>
  <div class="tooltip-content">This is the tooltip text.</div>
  </div>
</div>
          </div>
          <Loader loading={loading} />
          <div className=" mt-3 custom_table text-center w-100">
            <table className="w-100">
              <thead>
                <tr>
                  <th>Stakeholder</th>
                  <th>Responsible (R)</th>
                  <th>Accountable (A)</th>
                  <th>Consulted (C)</th>
                  <th>Informed (I)</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody className="mains">
                {matData?.map((row, index) => (
                  <tr key={index} className="main ">
                    <td>
                      {" "}
                      <div>{row.stakeholder}</div>
                    </td>
                    <td>
                      <div className="w-100">
                        {row?.raciA === true ? <span>&#10004;</span> : ""}
                      </div>
                    </td>
                    <td>
                      <div className="w-100">
                        {row?.raciR === true ? <span>&#10004;</span> : ""}
                      </div>
                    </td>
                    <td>
                      <div className="w-100">
                        {row?.raciC === true ? <span>&#10004;</span> : ""}
                      </div>
                    </td>
                    <td>
                      <div className="w-100">
                        {row?.raciI === true ? <span>&#10004;</span> : ""}
                      </div>
                    </td>
                    {
                        row?.clientEnable === true ? 
                        <td>
                        <div>
                          <input
                            type="text"
                            name="name"
                            value={row.contactName}
                            onChange={(e) =>
                              handleNameChange(index, e.target.value)
                            }
                          />
                        </div>
                      </td> : 
                        <td className="disablebacks">
                        <div>
                          <input
                            disabled ={true}
                            type="text"
                            name="name"
                            value={row.contactName}
                            onChange={(e) =>
                              handleNameChange(index, e.target.value)
                            }
                          />
                        </div>
                      </td>
                      }
                   {
                        row?.clientEnable === true ? 
                        <td >
                      <div>
                        <input
                          type="Email"
                          name="email"
                          value={row.raciEmail}
                          onChange={(e) =>
                            handleEmailChange(index, e.target.value)
                          }
                        />
                      </div>
                    </td> : 
                    <td className="disablebacks" >
                    <div>
                      <input
                      
                      disabled={true}
                        type="Email"
                        name="email"
                        value={row.raciEmail}
                        onChange={(e) =>
                          handleEmailChange(index, e.target.value)
                        }
                      />
                    </div>
                  </td>
                   }
                    { row?.clientEnable === true ? 
                    <td className="over_flw_h">
                      <div>
                        {" "}
                        <PhoneInput
                          defaultCountry="IN"
                          value={row.phoneNumber}
                          countryCodeEditable={true}
                          // onCountryChange={(value) =>
                          //   handleContactChangecode(index, value)}
                            
                          onChange={(value) =>
                            handleContactChange(index, value,)
                          }
                        />
                      </div>
                    </td> : 
                      <td className="over_flw_h disablebacks">
                      <div>
                        {" "}
                        <PhoneInput
                        disabled={true}
                          defaultCountry="IN"
                          value={row.phoneNumber}
                          countryCodeEditable={true}
                          // onCountryChange={(value) =>
                          //   handleContactChangecode(index, value)}
                          onChange={(value) =>
                            handleContactChange(index, value)
                          }
                        />
                      </div>
                    </td>
                }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center m-3 flex justify-center" onClick={handleUpdate}>
            <Mainbtn type="button" buttonText="Update Matrix" />
          </div>
        </div>
      </div>

      {/* <Comment data={userData} /> */}
      <div className="p-5">
  {userData &&
    userData?.projectMilestoneList?.map((milestone, milestoneIndex) => {
      return (
        <>
          <Accordion
            key={milestoneIndex}
            expanded={expanded[milestoneIndex]}
          >
            <AccordionSummary
              aria-controls={`panel${milestoneIndex}-content`}
              id={`panel${milestoneIndex}-header`}
              expandIcon={
                <ArrowForwardIosSharpIcon style={{ cursor: "pointer" }} />
              }
              sx={{ cursor: "unset !important" }}
            >
              <div className="d-flex justify-content-between align-items-center w-100" ref={raciservice}>
                <div className="col-lg-9">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="swatches">
                          <a
                            className={
                              "" +
                              (milestone?.status == true ? " active" : "")
                            }
                            data-filter-name="Color"
                            data-filter-value="Cream"
                            title="Cream"
                            href="#"
                          ></a>
                        </div>
                        <p className="mx-5">{milestoneIndex + 1}</p>
                        <p>{milestone.projectMilestoneName}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center color_text">
                      <p>
                        {milestone?.projectSubMilestoneDtoList?.length}
                      </p>
                      <p className="mx-1">Sub-Tasks</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2">
                  {" "}
                  <div className=" d-flex justify-content-between align-items-center">
                    {" "}
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {" "}
                <div ref={raciLinks}>
                  {milestone?.projectSubMilestoneDtoList
                    ?.sort((a, b) => a.subMilestoneNo - b.subMilestoneNo)
                    .map((subMilestone, subMilestoneIndex) => (
                      <div key={subMilestoneIndex} className="px-5">
                        <div className="w-100 d-flex  align-items-center mt-2">
                          <div className="col-lg-12">
                            <div className=" d-flex  align-items-start disabled">
                              <div className="swatches">
                                <a
                                  className={
                                    "" +
                                    (subMilestone?.status === true
                                      ? " active"
                                      : "")
                                  }
                                  data-filter-name="Color"
                                  data-filter-value="Cream"
                                  sss
                                  title="Cream"
                                  href="#"
                                ></a>
                              </div>
                              <div className="col-lg-1">
                                <p className="mx-4">
                                  {`${milestoneIndex + 1}.${subMilestoneIndex + 1}`}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <p>
                                  {subMilestone?.projectSubmilestoneName}
                                </p>
                              </div>
                              <div className="col-lg-3">
                                <span>
                                  {subMilestone?.status === true
                                    ? "Complete"
                                    : "Incomplete"}
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <span
                                  onClick={() =>
                                    handleComent(
                                      subMilestone?.projectSubmilestoneId
                                    )
                                  }
                                >
                                  <button>View Comment</button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </>
      );
    })}
</div>


      <Downloadfiles data={userData}  raciComments={raciComments} />
    </div>
  );
};

export default Userupdate;
