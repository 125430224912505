import React from "react";
import { Link } from 'react-router-dom';
import styles from "../scope/scope.module.css";
import Filt from "../../../Assets/images/filter.png";

const data = [
  {
    id: 1,
    title: "Ransomware BIA Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
  {
    id: 2,
    title: "Cybersecurity Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
 
];

const data1 = [
  {
    id: 1,
    title: "Ransomware BIA Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
  {
    id: 2,
    title: "Cybersecurity Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
 
];

const data2 = [
  {
    id: 1,
    title: "Ransomware BIA Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
  {
    id: 2,
    title: "Cybersecurity Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
  {
    id: 3,
    title: "Ransomware BIA Assessment",
    subtitle : ": Operational Service",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adipiscing elit, sed do eiusmodveniam.",
  },
];

const index = () => {
  return (
    <>
    <div className="overview">
<div>
  <div className={styles.filterservice}>
    <div className={styles.filterservicehead}><h2>Service Wizard</h2></div>
    <div className={styles.filterimg}><img src={Filt} alt="" /></div>
  </div>
  <div className={styles.scopefilter}><span>Results (7)</span></div>
</div>


    <div className={styles.mainscopetitle}>
    <div><h3 className={styles.scopetitlehead}>Short-term scope (2)</h3></div>
      <div class="grid grid-rows-1 grid-cols-2 gap-8">
      {data.map((servicescope) => (
        <Link to="/services/servicedetail">
        <div key={servicescope.id} className={styles.scopetile}>
          <div><span className={styles.filterservicetitle}>{servicescope.title}</span> <span>{servicescope.subtitle}</span></div>
          <div>
            <p>
            {servicescope.desc}
            </p>
          </div>
        </div>
        </Link>
        ))}
      </div>
</div>

<div className={styles.mainscopetitle}>
<div><h3 className={styles.scopetitlehead}>Medium-term scope (2)</h3></div>
      <div class="grid grid-rows-1 grid-cols-2 gap-8">
      {data1.map((servicescope) => (
        <Link to="/services/servicedetail">
        <div key={servicescope.id} className={styles.scopetile}>
          <div><span className={styles.filterservicetitle}>{servicescope.title}</span> <span>{servicescope.subtitle}</span></div>
          <div>
            <p>
            {servicescope.desc}
            </p>
          </div>
        </div>
        </Link>
        ))}
      </div>
</div>

<div className={styles.mainscopetitle}>
<div><h3 className={styles.scopetitlehead}>Long-term scope (3)</h3></div>
      <div class="grid grid-rows-1 grid-cols-2 gap-8">
      {data2.map((servicescope) => (
        <Link to="/services/servicedetail">
        <div key={servicescope.id} className={styles.scopetile}>
          <div><span className={styles.filterservicetitle}>{servicescope.title}</span> <span>{servicescope.subtitle}</span></div>
          <div>
            <p>
            {servicescope.desc}
            </p>
          </div>
        </div>
        </Link>
        ))}
      </div>
      </div>
      </div>
    </>
  );
};

export default index;
