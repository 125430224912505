import React, { useMemo } from "react";
import { useTable } from "react-table";
import MOCK_DATA from "../mockdata/data.json";
import { COLUMNS } from "../columns";
import Updatebtn from "../button";
import styles from "../table/table.module.css";

export const BasicTable = ({ data, columns }) => {

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <>
      <div className={styles.tabback}>
        {/* <div>
          <h3>RACI Matix</h3>
          <span>Stakeholders required to be involved in the RBIA.</span>
        </div> */}
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      
      </div>
    </>
  );
};

export default BasicTable;
