import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormControl from "../../controls/formcontrol";
import Modal from "@mui/material/Modal";
import Invitebtn from "../../button";
import Button from "@mui/material/Button";
import { GETOWNSERVICEApi, SendInvite } from "../../../../Utils/services";
import styles from "../addmember/addmember.module.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import addmemberimg from "../../../../Assets/images/addmember.png";
import AuthContext from "../../../../Utils/auth-context";
import { useContext } from "react";

const DownloadModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const authCtx = useContext(AuthContext);
  const data = localStorage?.getItem("user");
  const isJSONString = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  const fetchData = async () => {
    try {
      const result = await GETOWNSERVICEApi(JSON.parse(data)?.companyId);
      if (result?.res?.status === true) {
        const result2 = result.res.data?.map((res) => ({
          label: res.serviceName,

          value: res.purchasetaskId,
          id: res.purchasetaskId,
        }));
        setProjects(result2);
      }
    } catch { }
  };
  useEffect(() => {
    fetchData();
  }, [JSON.parse(data)?.companyId]);

  const allData = {
    email: "",
    roleId: "",
    PurchasedTaskIds: [],
    companyId: "",
  };

  const handle = async (values) => { debugger
    if (isJSONString(authCtx?.user)) {
      const ParseData = JSON?.parse(authCtx.user);
      const data = {
        email: values.formData.email,
        isInvited: true,
        companyId: ParseData?.companyId,
        roleId: values?.formData?.roleId,
        purchasedTaskIds: values?.formData?.roleId === 1 ? values.formData.PurchasedTaskIds : [values.formData.PurchasedTaskIds],

      };
      try {
        const result = await SendInvite(data);
        if (result?.res.status == true) {
          setLoading(false);
          toast.success(result?.res.message || "Raci created successfully");
          onClose();
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
        onClose();
      }

    } else {
      const data = {
        email: values.formData.email,
        isInvited: true,
        companyId: authCtx?.user?.companyId,
        roleId: values?.formData?.roleId,
        purchasedTaskIds: values?.formData?.roleId === 1 ? values.formData.PurchasedTaskIds : [values.formData.PurchasedTaskIds],

      };
      try {
        const result = await SendInvite(data);
        if (result?.res.status == true) {
          setLoading(false);
          toast.success(result?.res.message || "Raci created successfully");
          onClose();
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
        onClose();
      }
    }
    setLoading(true);

  };
  const schema = Yup.object().shape({
    formData: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),

    }),
  });
  const options = [
    { id: 1, label: "Admin", value: 1 },
    { id: 2, label: "Customer", value: 2 },
  ];

  return (
    <>
      <Modal open={open} onClose={onClose} className={styles.addmodel}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            height: 400,
            backgroundColor: "#fff",
            //   border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            padding: "50px",
          }}
        >
          <div>
            <div>
              <h2 className={styles.maintext}>Invite Members</h2>
            </div>

            <div className={styles.addmembrbox}>
              <div>
                <Formik
                  initialValues={{
                    formData: allData,
                  }}
                  onSubmit={handle}
                  validationSchema={schema}
                >
                  {(formik) => (
                    <Form className={styles.form_profile}>
                      <div className="row text-center ">

                        <div className="col-lg-12 mb-2">
                          <FormControl
                            control="Select"
                            name="formData.roleId"
                            placeholder="Role"
                            options={options}
                            prevalue={formik.values.formData.roleId}
                          />
                        </div>
                        <div className="col-lg-12 mb-2">
                          <FormControl
                            control="Select"
                            name="formData.PurchasedTaskIds"
                            options={projects}
                            placeholder="Service"
                            disabled={formik?.values?.formData?.roleId == 1}
                            prevalue={formik.values.formData.PurchasedTaskIds}
                          />

                        </div>
                        <div className="col-lg-12 mb-2">
                          <FormControl
                            control="Input"
                            placeholder="Email Id"
                            name="formData.email"
                          ></FormControl>
                        </div>
                      </div>

                      <div className={styles.sdinvite}>
                        <button className={styles.sumback} type="submit">
                          Send Invite
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const App = (props) => { 
  const [modalOpen, setModalOpen] = useState(false);
  const { data } = props;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className={styles.addmem}>
        <span style={{ cursor: "pointer" }} className={styles.headaddmem} onClick={props?.scrollToRaciMatrix}>RACI Matix</span>
        <span style={{ cursor: "pointer" }} className={styles.headaddmem} onClick={props?.scrollToService}>Service Status</span>
        <span style={{ cursor: "pointer" }} className={styles.headaddmem} onClick={props?.scrollToLinks}>Comments</span>
        <span style={{ cursor: "pointer" }} className={styles.headaddmem} onClick={props?.scrollToComments}>Links & Files</span>
      </div>

      <div className={styles.addmembtn}>
        <h1>{data?.serviceName}</h1>
        <Button variant="contained" onClick={handleOpenModal}>
          <div className={styles.addbtn}>
            <div className={styles.addbtnimg}>
              <img src={addmemberimg} alt="" />
            </div>
            <div className={styles.addbtntext}>
              <span>Add Members</span>
            </div>
          </div>
        </Button>
        <DownloadModal open={modalOpen} onClose={handleCloseModal} />
      </div>
    </>
  );
};

export default App;
