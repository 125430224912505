import React, { useEffect } from "react";
import { GETCOMMENTONEAPI } from "../../../../Utils/services";
import { useState } from "react";
import { toast } from "react-toastify";

function Comment(props) {
  const { id, closeModal } = props;
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await GETCOMMENTONEAPI(id); //
        if (result?.res?.status === true) {
          setCommentData(result.res.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(commentData, "commentData");
  function calculateTimeDifference(providedTimestamp) {
    // Current timestamp
    const currentTime = new Date();
    const givenTime = new Date(providedTimestamp);

    // Calculate the time difference in milliseconds
    const timeDifference = currentTime - givenTime;

    // Convert the time difference to days, hours, minutes, and seconds
    const millisecondsInSecond = 1000;
    const millisecondsInMinute = millisecondsInSecond * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;

    if (timeDifference >= millisecondsInDay) {
      const days = Math.floor(timeDifference / millisecondsInDay);
      return `${days} days`;
    } else if (timeDifference >= millisecondsInHour) {
      const hours = Math.floor(timeDifference / millisecondsInHour);
      return `${hours} hours`;
    } else if (timeDifference >= millisecondsInMinute) {
      const minutes = Math.floor(timeDifference / millisecondsInMinute);
      return `${minutes} minutes`;
    } else {
      const seconds = Math.floor(timeDifference / millisecondsInSecond);
      return `${seconds} seconds`;
    }
  }

  return (
    <div>
      <div className="row">
        <div className="text-center">
          <h2>Comments</h2>
        </div>
        <div className="text-center my-2">
          <h4>{commentData[0]?.subMileStoneName}</h4>
        </div>
        <div className="commenst">
          {commentData &&
            commentData.map((res, index) => {
              console.log(res, "res");
              return (
                <div className="row comment_wrap">
                  <div className="col-lg-2">
                    <div className="comment_image_wrap">
                      <div className="comment_role">
                        <p>{res?.roleName}</p>
                      </div>
                      <div className="comment_image"></div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="comment_content">
                      <div className="comment_tex_wrapper">
                        <div className="comment_tex_tile">
                          <p className="comm_name">{res?.userName}</p>
                          <p className="comm_text" dangerouslySetInnerHTML={{ __html: res?.commentText }}></p>
                        </div>
                      </div>
                      <div className="comment_tex_tile_time">
                        <span>
                          {calculateTimeDifference(res?.createdAt) + " ago"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Comment;
